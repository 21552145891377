// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.54);
}

.page-count {
  flex: 1 1 20%;
}

.page-pager {
  flex: 1 1 80%;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.page-skipper {
  padding: 0 10px;
}

.page-selector {
  padding: 0 10px;
  margin: 0;
  display: inline-block;
  list-style: none;
}

.pager {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 600;
}

.pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}

.pager a {
  height: 26px;
  min-width: 26px;
  line-height: 26px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
  outline: 0;
}

a.page-link:hover {
  background: rgba(0, 0, 0, 0.14);
}

.jump-page {
  color: rgba(0, 0, 0, 0.54);
  padding: 5px;
  cursor: pointer;
}

.pager li.disabled a {
  pointer-events: none;
}

div.disabled input, div.disabled i {
  pointer-events: none;
}

.disabled {
  cursor: not-allowed;
}

.pager li a {
  cursor: pointer;
  display: inline-block;
}

.pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: 700;
}

.pager-sm {
  justify-content: flex-end;
  display: flex !important;
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}
.page-link ion-icon {
  font-size: 16px;
  margin: 5px 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
