/* eslint-disable no-bitwise */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { LoadingController } from '@ionic/angular';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import 'firebase/messaging';
import { environment } from 'src/environments/environment';


export interface UserFlexfields {
 [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  GlobalCustomerName = 'Toon';
  DomainName: string;
  AuthenticationKey: string;
  alert: any;
  loading: any;
  ImageData: any;
  locales: any = [];
  isLoading = false;
  RoleCode = '';
  public alertPresented: any;
  private audio = new Audio();
  messaging: any;
  isCallAccepted: boolean;
  isCallInitiated: boolean;
  isCallRejected: boolean;
  timeOut: any;
  checkBackURL = false;
  showCallingUserInfo = false;
  isRouteFromLoginToVideoAssistance = false;
  enableVideoassistance: string;
  showSentAlert: boolean;
  incidentCountTimer: any;
  reportedIncidentCount = 0;
  enableMSLogin: Boolean= false;
  enableGoogleLogin: Boolean = false;
  enableMMLogin: Boolean = false;
  lang = '1043';
  showQrScanner = true;
  secretKey: string = null;
  tempUserName: any = null;
  menuItems: any = [];
  localeLength = '';
  showDomainChange = false;
  currentUserId = '';
  switchDomainsName: any;
  public onDomainChange: EventEmitter<any> = new EventEmitter<any>();
  supportUser = false;
  hideDefault = false;
  customLocaleSelect = {
    cssClass: 'customLocaleSelect',
  };
  customSelectInterface = {
    cssClass: 'customSelectInterface',
  };
  domainColor = '';
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  valueChanges = false;
  domains: any = [];
  configDomain: any;
  loadingCounter = 0;
  length= 0;
  currentPage = 0;
  searchText = '';
  activeElement:any;
  constructor(public loadingController: LoadingController,
    public alertController: AlertController,
    public _router: Router,
    private _ngZone: NgZone) {
    this.alertPresented = false;
    if(localStorage.enableMSLogin && localStorage.enableMSLogin != '' && localStorage.enableMSLogin != 'undefined' && localStorage.enableMSLogin != 'null' && localStorage.enableMSLogin == 'true') {
      this.enableMSLogin = true;
    }
    if(localStorage.enableGoogleLogin && localStorage.enableGoogleLogin != '' && localStorage.enableGoogleLogin != 'undefined' && localStorage.enableGoogleLogin != 'null' && localStorage.enableGoogleLogin == 'true') {
      this.enableGoogleLogin = true;
    }
    if(localStorage.enableMMLogin && localStorage.enableMMLogin != '' && localStorage.enableMMLogin != 'undefined' && localStorage.enableMMLogin != 'null' && localStorage.enableMMLogin == 'true') {
      this.enableMMLogin = true;
    }
    this.currentUrl = this._router.url;
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  async presentLoading() {
    this.isLoading = true;
    this.activeElement = <HTMLElement> document.activeElement;
    this.activeElement?.blur();
    // return await this.loadingController.create({
    //   message: message
    // }).then(a => {
    //   a.present().then(() => {
    //     // console.log('presented');
    //     if (!this.isLoading) {
    //       a.dismiss().then(() => console.log('abort presenting'));
    //     }
    //   });
    // });
    // //await this.loading.present();
  }

  async dismiss() {
    this.isLoading = false;
    this.activeElement?.focus();
    // return await this.loadingController.dismiss().then(() => {
    //   // console.log('dismissed')
    // });
  }

  async presentAlert(header, errMessage, unAuthMessage, status) {
    if(!this.alertPresented){
      let message = errMessage;
      if (status == 401) {
        message = unAuthMessage ? unAuthMessage: errMessage;
      }
      this.alertPresented = true;
      this.alert = await this.alertController.create({
        header,
        message,
        buttons: [{
          text: 'Ok',
          handler: () => {
            this.alertPresented = false;
            if (status == 401) {
              this.clearSettings();
              this._ngZone.run( () => {
                this._router.navigate(['/login']);
              } );
            } else {

            }
          }
        }],
        backdropDismiss: false
      });
      await this.alert.present();
    }
  }

  startTimer(status) {
    if (status == 'receiving') {
      this.audio.src = '../assets/videoCallRing.mp3';
  }else {
      this.audio.src = '../assets/ringing.mp3';
  }
    this.audio.load();

    const promise = this.audio.play();
    if (promise) {
      //Older browsers may not return a promise, according to the MDN website
      promise.catch(function(error) {
        console.error(error);
       });
      }
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      localStorage.isTimerStop = true;
        this.audio.pause();
    }, 30000);
  }

  pauseTimer() {
    this.audio.pause();
  }

  translate(labelText){
    try {
      const text = JSON.parse(localStorage.translations)[labelText];
      return text ? text : labelText;
    } catch (error) {
      return labelText;
    }
  }


  async isNotificationPermissions() {
    if (navigator && navigator.permissions) {
      try {
        const result = await navigator.permissions.query({ name: 'notifications' });
        if (result) {
          if (result.state === 'granted') {
            return true;
          } else {
            return false;
          }
        }
      } catch (e) {
        // This is only currently supported in Chrome.
        // https://stackoverflow.com/a/53155894/2410379
        return true;
      }
    }

    return false;
  }

  uuid() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
        const v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  }

  getPreviousUrl(){
    return this.previousUrl;
  }

  clearSettings() {
    const imageData = localStorage.ImageData ? localStorage.ImageData : '';
    const domainLocales = localStorage.locales ? localStorage.locales : '';
    const lang = localStorage.locale ? localStorage.locale : '';
    const translations = localStorage.translations ? localStorage.translations : '';
    let oAuth;
    if (localStorage.oAuth) {
      oAuth = localStorage.oAuth == 'true' ? true : false;
    }
    const customerColor = localStorage.customerColor ? localStorage.customerColor : '';
    const domain = localStorage.domain ? localStorage.domain : '';
    const enableGoogleLogin = localStorage.enableGoogleLogin && localStorage.enableGoogleLogin == 'true' ? true : false;
    const enableMSLogin = localStorage.enableMSLogin  && localStorage.enableMSLogin == 'true' ? true : false;
    const enableMMLogin = localStorage.enableMMLogin && localStorage.enableMMLogin == 'true' ? true : false;
    const url = localStorage.url ? localStorage.url : '';
    const ssoUrl = localStorage.ssoUrl ? localStorage.ssoUrl : '';
    const appVersion = localStorage.appVersion ? localStorage.appVersion : '';
    const externalLink =localStorage.externalLink ? localStorage.externalLink : '';
    const externalURL =localStorage.externalURL ? localStorage.externalURL : '';
    const domainColor = localStorage.domainColor ? localStorage.domainColor : '';
    const ssoData = localStorage.ssoData ? JSON.parse(localStorage.ssoData) : [];
    const hideChangeDomainLogin = localStorage.HideChangeDomainLogin;
    clearTimeout(this.incidentCountTimer);

    localStorage.clear();

    if(domain) {localStorage.domain = domain;}
    if(customerColor) {localStorage.customerColor = customerColor;}
    if(oAuth) {localStorage.oAuth = oAuth;}
    if(imageData) {localStorage.ImageData = imageData;}
    if (domainLocales ) {
      localStorage.locales = domainLocales;
    }
    if(lang) {localStorage.locale = lang;}
    if(translations) {localStorage.translations = translations;}
    localStorage.showLocaleSelect = false;
    if(enableMSLogin) {localStorage.enableMSLogin = enableMSLogin;}
    if(enableGoogleLogin) {localStorage.enableGoogleLogin = enableGoogleLogin;}
    if(enableMMLogin) {localStorage.enableMMLogin = enableMMLogin;}
    if(appVersion) {localStorage.appVersion = appVersion;}
    if(externalLink) {localStorage.externalLink = externalLink;}
    if(externalURL) {localStorage.externalURL = externalURL;}
    if(hideChangeDomainLogin) {localStorage.HideChangeDomainLogin = hideChangeDomainLogin;}
    if(ssoData.length > 0) {localStorage.ssoData = JSON.stringify(ssoData);}
    localStorage.url = url ? url : environment.url;
    localStorage.ssoUrl = ssoUrl ? ssoUrl : environment.ssoUrl;
    localStorage.domainColor = domainColor ? domainColor : '';
  }

  getFlagForLocales(){
    this.length++;
    this.length = this.length-1;
    console.log(this.length++);
    if (this.length % 2 === 1) {
    setTimeout( () => {
      const alertButtons = document.querySelectorAll('.customLocaleSelect button.alert-radio-button');
      if(alertButtons.length > 0) {
        alertButtons.forEach((button, index) => {
          button.querySelector('.alert-button-inner').insertAdjacentHTML('beforeend', '<span class=\'flag-icon \'></span>');
          button.querySelector('.flag-icon').className += this.locales[index].LocaleIconUrl;
        });
      } else {
        this.getFlagForLocales();
      }
    },100 );
  }
}

getFlag(){
  setTimeout( () => {
    const alertButtons = document.querySelectorAll('.customLocaleSelect button.alert-radio-button');
    if(alertButtons.length > 0) {
      alertButtons.forEach((button, index) => {
        button.querySelector('.alert-button-inner').insertAdjacentHTML('beforeend', '<span class=\'flag-icon \'></span>');
        button.querySelector('.flag-icon').className += this.locales[index].LocaleIconUrl;
      });
    } else {
      this.getFlag();
    }
  },200 );
}

  getFlagForLocalesCheckbox(){
    this.length++;
    this.length = this.length-1;
    console.log(this.length++);
    if (this.length % 2 === 1) {
    setTimeout( () => {
      const alertButtons = document.querySelectorAll('.customLocaleSelect button.alert-checkbox-button');

      if(alertButtons.length > 0) {
        alertButtons.forEach((button, index) => {
          button.querySelector('.alert-button-inner').insertAdjacentHTML('beforeend', '<span class=\'flag-icon flag-icon-checkbox \'></span>');
          button.querySelector('.flag-icon').className += this.locales[index].LocaleIconUrl;
        });
      } else {
        this.getFlagForLocalesCheckbox();
      }
      this.length = 0;
    },100 );
  }
  }

getTimeZone() {
    const offset = new Date().getTimezoneOffset(); const o = Math.abs(offset);
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
}

presentRouteConfirm() {
  return new Promise( async (resolve, reject) => {
    const alert = await this.alertController.create({
      header: this.translate('label-pleaseConfirm'),
      message: this.translate('label-routeConfirmation'),
      cssClass: 'alertConfirmation',
      buttons: [
        {
          text: this.translate('label-cancel'),
          handler: async () => {
            resolve('Cancel');
          }
        },
        {
          text: this.translate('label-leavePage'),
          handler: () => {
            resolve('No');
          }
        }
        // , {
        //   text: this.translate('label-yes'),
        //   handler: async () => {
        //     resolve("Yes");
        //   }
        // }
      ]
    });
    await alert.present();
  } );
}

findElement(arr, node, key, keyValue) {
  let matches = [];
  if (!Array.isArray(arr)) {return matches;}


  arr.forEach(i => {
    if(keyValue.split('/').length > 3 ) {
      keyValue = keyValue.split('/')[0] + '/' + keyValue.split('/')[1] + '/' + keyValue.split('/')[2];
    }
    const routeUrl = i[key].toLowerCase().replace('/', '');
      if(keyValue.includes(routeUrl)) {
        matches.push(i);
      } else {
          const childResults = this.findElement(i[node], node, key, keyValue);
          if (childResults.length) {matches = [ ...matches, ...childResults];}
      }
  });

  if(keyValue.includes('checklistdetails') || keyValue.includes('answerdetails') ){
    matches.push(keyValue);
  }
  return matches;
}
clearchangeDomainData(){
  localStorage.removeItem('AuthenticationKey');
  localStorage.removeItem('UserId');
  localStorage.removeItem('DomainId');
  localStorage.removeItem('DomainName');
  localStorage.removeItem('Domains');
  localStorage.removeItem('UserName');
  localStorage.removeItem('Locale');
  localStorage.removeItem('RoleCode');
  localStorage.removeItem('DomainLocales');
  localStorage.removeItem('MenuItems');
  localStorage.removeItem('ImageData');
}
async getDomains(){
  if (localStorage.switchDomain && localStorage.switchDomain != 'null' && JSON.parse(localStorage.switchDomain).length > 0){
    this.domains = this.switchDomainsName;
  }
}
switchDomain(){
  const currentUser = localStorage.getItem('RoleCode');
  const switchDomains = JSON.parse(localStorage.switchDomain);
  if (localStorage.webSetting) {
    const data = JSON.parse(localStorage.webSetting);
    data.map(element => {
      if (element.SettingName.toLowerCase() == 'enableusersupport') {
        if (element.SettingValue == 'true'  && switchDomains.length > 0) {
         this.showDomainChange = true;
        }
        else{
          this.showDomainChange = false;
        }
      }
    });
  }

}

  startLoading() {
    if (this.loadingCounter == 0) {
      this.presentLoading();
    }
    this.loadingCounter++;

  }

  dismissLoading(resetCounter = false) {
    if(resetCounter){
      this.dismiss();
      this.loadingCounter = 0 ;
    } else {
      if (this.loadingCounter == 1) {
        this.dismiss();
      }
      this.loadingCounter--;
    }
  }

  changePopupWidth(){
    setTimeout(() => {
    const elementsToStyle = document.querySelectorAll('.sc-ion-alert-md-h');
    elementsToStyle.forEach((element) => {
      element.classList.add('conditionally-styled');
    });
  }, 60);
}

openFilters(){
  setTimeout(() => {
    const length = document.getElementsByClassName('filterCss').length;
    for (let i = 0; i < length; i++){
      const k: any = document.getElementsByClassName('filterCss')[i].shadowRoot.querySelector('.select-wrapper-inner');
      if (k) {
        k.style.display = 'contents';
      }
    }
  }, 0);
  // setTimeout(() => {
  //   const length = document.getElementsByClassName('filterCss').length;
  //   for (let i = 0; i < length; i++){
  //     const k: any = document.getElementsByClassName('filterCss')[i].shadowRoot.querySelector('.select-wrapper-inner');
  //     if (k) {
  //       k.style.display = 'contents';
  //     }
  //   }
  // }, 0);
}

}

