// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  display: flex;
  padding: 22px 23px 15px 23px;
}

.modal-select-wrapper {
  width: 100% !important;
  border-radius: 4px;
}

.header-container {
  display: inline;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  height: 48px;
  margin-right: 10px !important;
  margin-left: 2px !important;
}

.header-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin-left: 1px !important;
  height: 0;
  width: 0;
}

.checkmark-header {
  position: absolute;
  top: 0;
  background-color: #fff;
  left: 1px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--ion-color-step-550, #737373);
}

.header-container input:checked ~ .checkmark-header {
  display: none;
}

.header-container input:checked ~ .fa-check-square {
  display: inline;
  top: -2px;
  left: 0px;
  font-size: 18px;
  position: absolute;
  color: var(--ion-color-primary);
}

.select-title {
  display: inline;
  font-size: 20px !important;
  font-weight: 500;
  margin-left: 35px !important;
  text-transform: capitalize;
}

.select-footer-button {
  padding: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  height: 67px;
  bottom: 0;
}

ion-checkbox {
  margin: 0px;
  height: 16px;
  width: 16px;
  --background-checked: var(--ion-color-primary) !important;
  --border-color-checked: var(--ion-color-primary) !important;
}

.alert-checkbox-group {
  flex: 1;
  margin-top: 10px;
  border-top: 1px solid var(--ion-color-step-150, #d9d9d9);
  border-bottom: 1px solid var(--ion-color-step-150, #d9d9d9);
  overflow: auto;
}

.alert-tappable {
  position: relative;
  height: 48px;
  overflow: hidden;
}

.alert-button,
.alert-checkbox,
.alert-input,
.alert-radio {
  outline: none;
}

.alert-tappable {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  background: transparent;
  font-size: inherit;
  line-height: normal;
  text-align: start;
  appearance: none;
  contain: strict;
}

.alert-button-inner,
.alert-tappable {
  display: flex;
  width: 100%;
}

.alert-button-inner {
  justify-content: flex-end;
}

.alert-button-inner,
.alert-tappable {
  display: flex;
  width: 100%;
}

.alert-button-inner {
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.alert-checkbox-icon {
  left: 26px;
  top: 0;
  border-radius: 2px;
  position: relative;
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--ion-color-step-550, #737373);
  contain: strict;
}

.alert-checkbox-icon,
.alert-checkbox-inner,
.alert-radio-icon {
  box-sizing: border-box;
}

.alert-checkbox-label {
  padding-left: unset;
  padding-right: unset;
  padding-inline-start: 53px;
  padding-inline-end: 26px;
}

.alert-checkbox-label {
  padding-left: 53px;
  padding-right: 26px;
  padding-top: 13px;
  padding-bottom: 13px;
  flex: 1;
  color: var(--ion-color-step-850, #262626);
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.aria-checked .alert-checkbox-icon {
  border-color: var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}

.aria-checked .alert-checkbox-inner {
  left: 3px;
  top: 0;
  position: absolute;
  width: 6px;
  height: 10px;
  transform: rotate(45deg);
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: #fff;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 69px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  height: 48px;
  align-items: center;
  display: flex;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 16px;
  background-color: #fff;
  left: 26px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--ion-color-step-550, #737373);
}

.container input:checked ~ .checkmark {
  display: none;
}

.fa-check-square {
  display: none;
}

.container input:checked ~ .fa-check-square {
  display: inline;
  left: 26px;
  font-size: 18px;
  position: absolute;
  color: var(--ion-color-primary);
}

.checkbox-button {
  height: 48px;
  background: none;
  width: 100%;
  text-align: start;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
