import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'checkListFilter'
})
export class ChecklistFilterPipe implements PipeTransform {
  transform(checklistDefinition: any, selectedChecklist: any): any {
        if(checklistDefinition === undefined || selectedChecklist.length <= 1){  
            return checklistDefinition;
        }
        let returnSet = new Set();
        selectedChecklist.forEach(selected => {
          checklistDefinition.filter(
            (checklist, index) => {
              if(checklist.FlexFieldDefinitionId != selected.checkListdDefinitionId){
                  returnSet.add(checklist);
              }
            }
          );
        });
        // console.log(returnSet)
        checklistDefinition = Array.from(returnSet);

        return checklistDefinition;
    }
  }