import { FilterPipe } from './filter.pipe';
import { NgModule } from '@angular/core';
import { RemovewhitespacesPipe } from './removeWhiteSpace.pipe';
import { ChecklistFilterPipe } from './checkListFilter.pipe';
import { OrderTranslatePipe } from './orderBy.pipe';

@NgModule({
	declarations: [
		FilterPipe,
		RemovewhitespacesPipe,
		ChecklistFilterPipe,
		OrderTranslatePipe,
	],
	imports: [

	],
	exports: [
		FilterPipe,
		ChecklistFilterPipe,
		RemovewhitespacesPipe,
		OrderTranslatePipe,
	]
})

export class Pipes { }