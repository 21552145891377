import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(answers: any, searchText: string): any {
        if(searchText === undefined){  
            return answers;
        }
        return answers.filter(
          answer => {
                return answer.Name.toLowerCase().includes(searchText.toLowerCase());
            }
        );
    }
}