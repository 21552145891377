// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  padding: 0px 15px 0px 15px;
}

.modal-footer-button {
  padding: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  height: 56px;
  bottom: 0;
  position: absolute;
}

.infoBlock-table-body {
  background: var(--ion-color-light);
  padding: 5px;
  border-bottom: 1px solid var(--ion-color-light-shade);
  min-height: 45px;
  padding-left: 10px;
}

.infoBlock-table-header {
  background: var(--ion-color-primary);
  color: #fff;
  padding: 5px;
  padding-left: 10px;
  margin-top: 9px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
