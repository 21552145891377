import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginaccessGuard implements CanActivate {
  
  constructor(private _authService: AuthService, private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // if(localStorage.authenticationKey){
      //   this.router.navigate(['/home']);
      //   console.log("Denied");
      //   return false;
        
      // }
    if (localStorage.authenticationKey && localStorage.authenticationKey != '' && localStorage.authenticationKey != 'undefined') {
      this.router.navigate(['/home'], { replaceUrl: true });
      return false;
    }
    
      if(localStorage.domain && localStorage.domain != '' && localStorage.domain != "undefined" && localStorage.domain != "null") {
        return true;
      } else {
        this.router.navigate(['login/domain']);
      }
    return false;
  }
}