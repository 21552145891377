import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { MainService } from './main.service';

interface AuthToken {
  token: string;
}
export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
  providedIn: 'root'
})
export class VideochatService {
  token: string = '';
  $roomsUpdated: Observable<boolean>;
  notificationMessage: Observable<any>;
  bgNotificationMessage: Observable<any>;


  private roomBroadcast = new ReplaySubject<boolean>();
  private notifyBroadcast = new Subject<any>();
  // private bgNotificationBroadcast = new ReplaySubject<any>();


  constructor(private readonly http: HttpClient,
    private _mainService: MainService) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
    //this.notificationMessage =this.notifyBroadcast.asObservable();
    // this.bgNotificationMessage =this.bgNotificationBroadcast.asObservable()

  }


  getAuthToken(key): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Twilio/Token", key);
  };

  getctiveUsers(key): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Twilio/Users/Register/Mobile", key);
  }



  async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {
    let room;
    try {
      let token = await this.getAuthToken(localStorage.authenticationKey);
      room = await connect(token.token, { name, tracks, dominantSpeaker: true } as ConnectOptions);
      return room;
    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
      localStorage.registeredUser = '';
      localStorage.removeItem('registeredUser');
      return error.message;
    }

  }

  sendNotification(obj, key): Promise<any> {
    let query = "Twilio/Notification";
    return this._mainService.callWebserviceHttpPost(query, obj, key);

  }

  nudge() {
    this.roomBroadcast.next(true);
  }

  notificationReceived(msg) {
    return this.notifyBroadcast.next(msg);


  }

  notificationClear() {
    this.notifyBroadcast.next('');
  }

  getNotification(): Observable<any> {
    return this.notifyBroadcast.asObservable();
  }

  updateAvailableStatus(data, isAvailable, key): Promise<any> {
    let obj = {
      "RoomName": data,
    }
    if (isAvailable == "true") {
      return this._mainService.callWebserviceHttpPatch("Twilio/Users/Disconnect", obj, key);
    } else {
      return this._mainService.callWebserviceHttpPatch("Twilio/Users/Connect", obj, key);
    }
  }
}
