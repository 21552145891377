import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  model: string = '';
  label: string = '';
  list: any = [];
  selectedAll: boolean;
  checkedItem: any =[];
  langChangeSubscription: Subscription;
  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
    private _translate: TranslateService,
  ) {
    this.langChangeSubscription = this._translate.onLangChange.subscribe((event) => {
      this.list.forEach(element => {
        element.isChecked = false;
      });
    });
   }
   ionViewWillEnter(){
    let data = this.list;
this.checkedItem = data.filter(element=> element.isChecked);
   }

   ionViewDidLeave(){
    this.langChangeSubscription.unsubscribe();
   }

  ngOnInit() {
    this.model = this.navParams.data.params.model;
    this.label = this.navParams.data.params.label;
    this.list = this.navParams.data.params.item;
    let checkedItem = this.navParams.data.params.item.filter(element=> element.isChecked);
    if(checkedItem.length == this.navParams.data.params.item.length) {
      this.selectedAll = true;
    } else {
      this.selectedAll = false;
    }
  }

  async closeModal() {
    var callBackData = {
      item: this.list,
      model: this.model
    };
    const onClosedData: Object = callBackData;
    this.modalController.dismiss(onClosedData);
  }
async cancelClicked(){
  console.log('list', this.checkedItem);
 this.list.forEach(element => {
  element.isChecked = false;
    this.checkedItem.forEach(data => {
      if(data.UserGroupId === element.UserGroupId){
        element.isChecked = true;
      }
    });
  });
  let callBackData = {
    item: this.list ,
    model: this.model
  };
  const onClosedData: Object = callBackData;
  this.modalController.dismiss(onClosedData);
}

  toggleSelectAll() {
    if(this.selectedAll) {
      this.list.forEach(element => {
        element.isChecked = true;
      });
    } else {
      this.list.forEach(element => {
        element.isChecked = false;
      });
    }
  }

  toggleSelect() {
    let uncheckedItem = this.list.filter(element=> element.isChecked == false);
    if(uncheckedItem.length >= 0) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }
  }

}
