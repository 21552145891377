import { NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Pipes } from './pipes/pipes.module';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { SharedModule } from 'src/app/shared.module';
// import { AuthInterceptor } from './helpers/auth.interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TwilioCallModalComponent } from './components/twilio-call-modal/twilio-call-modal.component';
import { TreeDragDropService } from './components/tree/common/treedragdropservice';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';



@NgModule({
  declarations: [
    AppComponent,
    TwilioCallModalComponent,
    MultiSelectComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({mode:'md', innerHTMLTemplatesEnabled: true}),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSmartModalModule.forRoot(),
    ReactiveFormsModule,
    Pipes,
    SharedModule,
    TranslateModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    ServiceWorkerModule.register('/firebase-messaging-sw.js', {enabled: true})
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TreeDragDropService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
