/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private _httpClient: HttpClient) { }

  callWebserviceHttpGet(webserviceApiName, authKey): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.get(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, { headers }).toPromise();
  }

  callWebserviceHttpGetByPaging(webserviceApiName, authKey): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Security-AuthKey': authKey ? authKey : '',
        'X-LoginSource': 'weboffice'
      }),
      observe: 'response' as 'body'
    };
    return this._httpClient.get(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, httpOptions).toPromise();
  }

  callWebserviceHttpGetNoHeader(webserviceApiName): Promise<any> {
    // return this._httpClient.get(localStorage.url + webserviceApiName).pipe(timeout(120000)).toPromise();
    return this._httpClient.get(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName).toPromise();
  }

  callWebserviceHttpGetById(id, webserviceApiName, authKey): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.get(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName + '/' + id, { headers }).toPromise();
  }

  callWebserviceHttpGetNoAuthKey(webserviceApiName) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this._httpClient.get(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, { headers }).toPromise();
  }

  callWebserviceHttpGetPaging(urls, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey
    });
    return this._httpClient.get(urls, { headers }).toPromise();
  }

  callWebserviceHttpPost(webserviceApiName, objParams, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.post(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  callWebserviceHttpPut(webserviceApiName, objParams, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.put(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  callWebserviceHttpPatch(webserviceApiName, objParams, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
    });
    return this._httpClient.patch(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  callWebserviceGetImage(webserviceApiName, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.patch(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, { headers }).toPromise();
  }

  callWebserviceHttpDelete(webserviceApiName, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.delete(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, { headers }).toPromise();
  }

  callWebserviceGetImageById(id, webserviceApiName, authKey) {
    const headers = new HttpHeaders({
      accept: 'text/xnl; charset=utf-8',
      'X-Security-AuthKey': authKey
    });
    return this._httpClient.get(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, { headers }).toPromise();
  }

  //post with password
  callWebserviceHttpPostWithPassword(webserviceApiName, objParams, password, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      Authorization: password,
      'X-LoginSource': 'weboffice',
    });
    return this._httpClient.post(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  //put with password
  callWebserviceHttpPutWithPassword(webserviceApiName, objParams, password, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey ? authKey : '',
      Authorization: password,
      'X-LoginSource': 'weboffice',
    });
    return this._httpClient.put(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  //delete with password
  callWebserviceHttpDeleteWithPassword(webserviceApiName, password, authKey) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
      Authorization: password,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.delete(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, { headers }).toPromise();
  }

  callWebserviceHttpPostFile(webserviceApiName, objParams, authKey) {
    const headers = new HttpHeaders({
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.post(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  callWebserviceHttpPutFile(webserviceApiName, objParams, authKey) {
    const headers = new HttpHeaders({
      'X-Security-AuthKey': authKey,
      'X-LoginSource': 'weboffice'
    });
    return this._httpClient.put(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, { headers }).toPromise();
  }

  callWebserviceHttpPostFileObserveProgress(webserviceApiName, objParams, authKey) {

    const httpOptions = {
      headers: new HttpHeaders({
        'X-Security-AuthKey': authKey,
        'X-LoginSource': 'weboffice',
      }),
      reportProgress: true,
      observe: 'events' as 'body'
    };
    return this._httpClient.post(localStorage.url ? localStorage.url + webserviceApiName : environment.url + webserviceApiName, objParams, httpOptions);
  }
  
}
