import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 50;
  @Input() maxPages = 5;
  @Input() count: any;
  @Input() skip: any;
  @ViewChild('currentPage') currentPage: ElementRef;

  pager: any = {};
  totalPageCount: any;
  constructor() { }

  ngOnInit() {
    // set page if items array isn't empty
    // if (this.count) {
    //   this.currentPage.nativeElement.value = '';
    //   // get new pager object for specified page
    //   this.pager = this.paginate(this.count, this.initialPage, this.pageSize, this.maxPages, this.skip);
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    // if(changes.pageSize){
    //   if (changes.pageSize.currentValue !== changes.pageSize.previousValue) {
    //     this.setPage(this.pager.currentPage);
    //   }
    // }

    if(changes.count) {
      if(changes.count.currentValue) {
        this.totalPageCount = Math.ceil(changes.count.currentValue / this.pageSize);
        //this.setPage(this.pager.currentPage);
        this.currentPage.nativeElement.value = '';
        this.pager = this.paginate(this.count, this.pager.currentPage, this.pageSize, this.maxPages, this.skip);
        // if(this.pager.currentPage>= 2 ){
        //   this.pager = this.paginate(this.count, 1, this.pageSize, this.maxPages, this.skip);
        // }else{
        //   this.pager = this.paginate(this.count,  this.pager.currentPage, this.pageSize, this.maxPages, this.skip);
        //   this.setPage(this.pager.currentPage);
        // }
      }
    }

    if(changes.skip && changes.skip.currentValue == 0) {
      this.currentPage.nativeElement.value = '';
      // get new pager object for specified page
      this.pager = this.paginate(this.count, 1, this.pageSize, this.maxPages, this.skip);
    }
  }

  public setPage(page: number) {
    if(page) {
      page = parseInt(page.toString());
    }
    this.currentPage.nativeElement.value = '';
    // get new pager object for specified page
    this.pager = this.paginate(this.count, page, this.pageSize, this.maxPages, this.skip);

    // get new page of items from items array
    //var pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);

    // call change page function in parent component
    this.changePage.emit(this.pager);
  }

  public setPageSize(pageSize:number, page:number){
    this.pageSize = pageSize;
    this.pager = this.paginate(this.count, page, this.pageSize, this.maxPages, this.skip);
    this.changePage.emit(this.pager);
  }

  paginate(totalItems, currentPage, pageSize, maxPages, skip) {
    if (currentPage === void 0) { currentPage = 1; }
    if (pageSize === void 0) { pageSize = 10; }
    if (maxPages === void 0) { maxPages = 10; }
    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);
    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    }
    else if (currentPage > totalPages) {
        currentPage = totalPages;
    }
    var startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    }
    else {
        // total pages more than max so calculate start and end pages
        var maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        var maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        }
        else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        }
        else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }
    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = Array.from(Array((endPage + 1) - startPage).keys()).map(function (i) { return startPage + i; });
    // return object with all pager properties required by the view
    var skip:any = (currentPage-1) * pageSize;
    this.totalPageCount = totalPages;

    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
        skip: skip
    };
}


}
