import { FlexfieldSettingsPageModule } from './flexfield-settings/flexfield-settings.module';
/* eslint-disable max-len */
import { PhishingDetailPageModule } from './phishing/phishing-detail/phishing-detail.module';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginaccessGuard } from './guards/loginaccess.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full' , redirectTo: localStorage.getItem('isLogin') ? 'home' : '**'},
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule), canActivate: [LoginaccessGuard] },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule), canActivate: [AuthGuard] },
  { path: 'branding', loadChildren: () => import('./branding/branding.module').then(m => m.BrandingPageModule), canActivate: [AuthGuard] },
  { path: 'items', loadChildren: () => import('./items/items.module').then(m => m.ItemsPageModule), canActivate: [AuthGuard] },
  { path: 'items/:id', loadChildren: () => import('./items/itemdetails/itemdetails.module').then(m => m.ItemdetailsPageModule), canActivate: [AuthGuard] },
  { path: 'answerdetails/:id/:itemid', loadChildren: () => import('./items/answerdetails/answerdetails.module').then(m => m.AnswerdetailsPageModule), canActivate: [AuthGuard] },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersPageModule), canActivate: [AuthGuard] },
  { path: 'users/:id', loadChildren: () => import('./users/userdetails/userdetails.module').then(m => m.UserdetailsPageModule), canActivate: [AuthGuard] },
  { path: 'beacons', loadChildren: () => import('./beacons/beacons.module').then(m => m.BeaconsPageModule), canActivate: [AuthGuard] },
  { path: 'beacons/:id', loadChildren: () => import('./beacons/beacondetails/beacondetails.module').then(m => m.BeacondetailsPageModule), canActivate: [AuthGuard] },
  { path: 'item-definitions', loadChildren: () => import('./item-definitions/item-definitions.module').then(m => m.ItemDefinitionsPageModule), canActivate: [AuthGuard] },
  { path: 'item-definitions/:id', loadChildren: () => import('./item-definitions/item-definitiondetails/item-definitiondetails.module').then(m => m.ItemDefinitiondetailsPageModule), canActivate: [AuthGuard] },
  { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportPageModule), canActivate: [AuthGuard] },
  { path: 'itemreport', loadChildren: () => import('./report/itemreport/itemreport.module').then(m => m.ItemreportPageModule), canActivate: [AuthGuard] },
  { path: 'scormreport', loadChildren: () => import('./report/scormreport/scormreport.module').then(m => m.ScormreportPageModule), canActivate: [AuthGuard] },

  { path: 'loginreport', loadChildren: () => import('./report/loginreport/loginreport.module').then(m => m.LoginreportPageModule), canActivate: [AuthGuard] },
  { path: 'testreport', loadChildren: () => import('./report/testreport/testreport.module').then(m => m.TestreportPageModule), canActivate: [AuthGuard] },
  { path: 'checklist-definitions', loadChildren: () => import('./checklist-definitions/checklist-definitions.module').then(m => m.ChecklistDefinitionsPageModule), canActivate: [AuthGuard] },
  { path: 'checklist-definitions/:id', loadChildren: () => import('./checklist-definitions/checklist-definitionsdetails/checklist-definitionsdetails.module').then(m => m.ChecklistDefinitionsdetailsPageModule), canActivate: [AuthGuard] },
  { path: 'select-locale', loadChildren: () => import('./select-locale/select-locale.module').then(m => m.SelectLocalePageModule), canActivate: [AuthGuard] },
  { path: 'checklistdetails/:id', loadChildren: () => import('./items/checklistdetails/checklistdetails.module').then(m => m.ChecklistdetailsPageModule), canActivate: [AuthGuard] },
  { path: 'questions', loadChildren: () => import('./questions/questions.module').then(m => m.QuestionsPageModule), canActivate: [AuthGuard] },
  { path: 'questioncategories', loadChildren: () => import('./questioncategories/questioncategories.module').then(m => m.QuestioncategoriesPageModule), canActivate: [AuthGuard] },
  { path: 'performancereport', loadChildren: () => import('./report/performancereport/performancereport.module').then(m => m.PerformancereportPageModule), canActivate: [AuthGuard] },
  { path: 'usergroups', loadChildren: () => import('./usergroups/usergroups.module').then(m => m.UsergroupsPageModule), canActivate: [AuthGuard] },
  { path: 'usergroups/:id', loadChildren: () => import('./usergroupdetail/usergroupdetail.module').then(m => m.UsergroupdetailPageModule), canActivate: [AuthGuard] },
  { path: 'answerreport', loadChildren: () => import('./report/answerreport/answerreport.module').then(m => m.UserquestionreportPageModule), canActivate: [AuthGuard] },
  { path: 'questionnairereport', loadChildren: () => import('./report/questionnairereport/questionnairereport.module').then(m => m.QuestionnairereportPageModule), canActivate: [AuthGuard] },
  { path: 'answerratingreport', loadChildren: () => import('./report/userrating/answerratingreport/answerratingreport.module').then(m => m.UserratingPageModule), canActivate: [AuthGuard] },
  { path: 'averageratingreport', loadChildren: () => import('./report/userrating/averagerating/averagerating.module').then(m => m.AverageratingPageModule), canActivate: [AuthGuard] },
  { path: 'averageratingreport/:id', loadChildren: () => import('./report/userrating/averagerating/answerratingdetail/answerratingdetail.module').then(m => m.AnswerratingdetailPageModule), canActivate: [AuthGuard] },
  { path: 'userrating', loadChildren: () => import('./report/userrating/userrating.module').then(m => m.UserratingPageModule), canActivate: [AuthGuard] },
  { path: 'Questionaire', loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnairePageModule), canActivate: [AuthGuard] },
  { path: 'questions/:id/:locale', loadChildren: () => import('./questions/questiondetails/questiondetails.module').then(m => m.QuestiondetailsPageModule), canActivate: [AuthGuard] },
  { path: 'questionratingreport', loadChildren: () => import('./report/userrating/questionratingreport/questionratingreport.module').then(m => m.QuestionratingreportPageModule), canActivate: [AuthGuard] },
  { path: 'averagequestionrating', loadChildren: () => import('./report/userrating/averagequestionrating/averagequestionrating.module').then(m => m.AveragequestionratingPageModule), canActivate: [AuthGuard] },
  { path: 'averagequestionrating/:id', loadChildren: () => import('./report/userrating/averagequestionrating/questionratingdetail/questionratingdetail.module').then(m => m.QuestionratingdetailPageModule), canActivate: [AuthGuard] },
  { path: 'video-assistant', loadChildren: () => import('./twilio-home/twilio-home.module').then(m => m.TwilioHomePageModule), canActivate: [AuthGuard] },
  { path: 'systemtranslations', loadChildren: () => import('./systemtranslations/systemtranslations.module').then(m => m.SystemtranslationsPageModule), canActivate: [AuthGuard] },
  { path: 'systemtranslations/:id', loadChildren: () => import('./systemtranslations/systemtranslationdetail/systemtranslationdetail.module').then(m => m.SystemtranslationdetailPageModule), canActivate: [AuthGuard] },
  { path: 'domain', loadChildren: () => import('./domain/domain.module').then(m => m.DomainPageModule), canActivate: [AuthGuard] },

  { path: 'domain/:id', loadChildren: () => import('./domain/domaindetail/domaindetail.module').then(m => m.DomaindetailPageModule), canActivate: [AuthGuard] },
  { path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesPageModule), canActivate: [AuthGuard] },
  { path: 'messages/:id', loadChildren: () => import('./messages/message-detail/message-detail.module').then(m => m.MessageDetailPageModule), canActivate: [AuthGuard] },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyPageModule) },

  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'forgot-password/:id', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'user/invitation/:id', loadChildren: () => import('./activate/activate.module').then(m => m.ActivatePageModule) },
  { path: 'dashboardinfo', loadChildren: () => import('./information-block/information-block.module').then(m => m.InformationBlockPageModule), canActivate: [AuthGuard] },
  { path: 'dashboardinfo/:id', loadChildren: () => import('./information-block/information-block-details/information-block-details.module').then(m => m.InformationBlockDetailsPageModule), canActivate: [AuthGuard] },
  { path: 'reported-incidents', loadChildren: () => import('./list-incident/list-incident.module').then(m => m.ListIncidentPageModule), canActivate: [AuthGuard] },
  { path: 'reported-incidents/:id', loadChildren: () => import('./list-incident/list-incident-details/list-incident-details.module').then(m => m.ListIncidentDetailsPageModule), canActivate: [AuthGuard] },
  { path: 'helptext', loadChildren: () => import('./helpcenter/helpcenter.module').then(m => m.HelpcenterPageModule), canActivate: [AuthGuard] },
  { path: 'login/domain', loadChildren: () => import('./login-domain/login-domain.module').then(m => m.LoginDomainPageModule) },
  { path: 'oauth', loadChildren: () => import('./sso-callback/sso-callback.module').then(m => m.SsoCallbackPageModule) },
  { path: 'notificationtemplates', loadChildren: () => import('./notification-list/notification-list.module').then(m => m.NotificationListPageModule), canActivate: [AuthGuard] },
  { path: 'notificationtemplates/:id', loadChildren: () => import('./notification-list/notification-details/notification-details.module').then(m => m.NotificationDetailsPageModule), canActivate: [AuthGuard] },
  { path: 'loginverification', loadChildren: () => import('./otpverification/otpverification.module').then(m => m.OtpverificationPageModule) },
  { path: 'setup2fa/:id/:username', loadChildren: () => import('./otpverification/intermediate2-fa/intermediate2-fa.module').then(m => m.Intermediate2FaPageModule) },
  { path: 'videoplayer/:id', loadChildren: () => import('./vimeo-video-player/vimeo-video-player.module').then(m => m.VimeoVideoPlayerPageModule) },
  { path: 'securecontent', loadChildren: () => import('./secure-content/secure-content.module').then(m => m.SecureContentPageModule) },
  { path: 'decision-tree', loadChildren: () => import('./decision-tree/decision-tree.module').then(m => m.DecisionTreePageModule), canActivate: [AuthGuard] },
  { path: 'decision-tree/:id', loadChildren: () => import('./decision-tree/decision-tree-details/decision-tree-details.module').then(m => m.DecisionTreeDetailsPageModule), canActivate: [AuthGuard] },
  { path: 'useroverviewreport', loadChildren: () => import('./report/useroverviewreport/useroverviewreport.module').then(m => m.UseroverviewreportPageModule), canActivate: [AuthGuard] },
  { path: 'notification-scheduler', loadChildren: () => import('./notify-knowledgetest/notify-knowledgetest.module').then(m => m.NotifyKnowledgetestPageModule), canActivate: [AuthGuard] },
  {
    path: 'scorms',
    loadChildren: () => import('./scroms/scroms.module').then(m => m.ScromsPageModule)
  },
  {
    path: 'scorms/:id',
    loadChildren: () => import('./scroms/scrom-detail/scrom-detail.module').then(m => m.ScromDetailPageModule)
  },
  {
    path: 'scormreport',
    loadChildren: () => import('./report/scormreport/scormreport.module').then(m => m.ScormreportPageModule)
  },
  {
    path: 'browseroverviewreport',
    loadChildren: () => import('./report/browseroverviewreport/browseroverviewreport.module').then(m => m.BrowseroverviewreportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'userimport',
    loadChildren: () => import('./user-import/user-import.module').then(m => m.UserImportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content.module').then(m => m.ContentPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'communication',
    loadChildren: () => import('./communication/communication.module').then(m => m.CommunicationPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'usermanagement',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'mainsettings',
    loadChildren: () => import('./main-settings/main-settings.module').then(m => m.MainSettingsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'performance',
    loadChildren: () => import('./report/performanceoverviewreport/performanceoverviewreport.module').then( m => m.PerformanceoverviewreportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'phishing',
    loadChildren: () => import('./phishing/phishing.module').then( m => m.PhishingPageModule), canActivate: [AuthGuard]
  },
  { path: 'phishing/:id',
    loadChildren: () => import('./phishing/phishing-detail/phishing-detail.module').then(m => m.PhishingDetailPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'phishingreport',
    loadChildren: () => import('./report/phishingreport/phishingreport.module').then( m => m.PhishingreportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'flexfield-settings',
    loadChildren: () => import('./flexfield-settings/flexfield-settings.module').then( m => m.FlexfieldSettingsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'emailnotificationreport',
    loadChildren: () => import('./report/emailnotificationsreport/emailnotificationsreport.module').then( m => m.EmailnotificationsreportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'knowledgetestquestionsreport',
    loadChildren: () => import('./report/knowledgetestquestionsreport/knowledgetestquestionsreport.module').then( m => m.KnowledgetestquestionsreportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'userprogressreport',
    loadChildren: () => import('./report/userprogressreport/userprogressreport.module').then( m => m.UserprogressreportPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'domainoverview',
    loadChildren: () => import('./report/domainoverview/domainoverview.module').then( m => m.DomainoverviewPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule), canActivate: [AuthGuard]
  },

  { path: '**', pathMatch: 'full', redirectTo: localStorage.getItem('domain') ? 'login' : 'login/domain', },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
