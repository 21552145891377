import { GlobalService } from './../services/global.service';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private _authService: AuthService, private router: Router, public global: GlobalService){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let domainUrl = next.queryParams ? next.queryParams.Domain ? next.queryParams.Domain : '' : '';  
      let menuItems = localStorage.menuItems ? JSON.parse(localStorage.menuItems) : [];

      if(!localStorage.authenticationKey || localStorage.authenticationKey == '' || localStorage.authenticationKey == 'undefined'){
        localStorage.externalLink = true;
        localStorage.externalURL = state.url;
        if(!localStorage.domain || localStorage.domain == '' || localStorage.domain == "undefined" || localStorage.domain == "null") {
          localStorage.domain = '';
        }
        if(domainUrl && domainUrl.toLowerCase() != localStorage.domain.toLowerCase()) {
          this.router.navigate(['login/domain'],{ replaceUrl: true, queryParams: {d: domainUrl} });
        } else {
          this.router.navigate(['login'], { replaceUrl: true });
        }      
        return false;
        
      } else {
        if(domainUrl && domainUrl.toLowerCase() != localStorage.domain.toLowerCase()) {
          localStorage.externalLink = true;
          localStorage.externalURL = state.url;
          this.router.navigate(['login/domain'],{ replaceUrl: true, queryParams: {d: domainUrl} });
          return false;
        } else {
        //   if(localStorage.RoleCode && localStorage.RoleCode.toLowerCase() == "admin" && localStorage.domain && localStorage.domain.toLowerCase() =="master") {
        //     return true;
        //   }
        // else 
        if( state.url === '/'|| state.url.includes('home')  || state.url.includes('loginverification') || state.url.includes('login')|| state.url.includes('privacy')|| state.url.includes('securecontent') || state.url.includes('oauth')){
         return true;
        }else{
//           let count = 0;
//           for (let i = 0; i < menuItems.length; i++) {
//             count = 0;
//             var nameArr = state.url;
//             if (nameArr.includes(menuItems[i].menuRouterLink.replace('/', '').toLowerCase())) {
//               return true;
//             } else {
//               if(menuItems[i].childMenuItems && menuItems[i].childMenuItems.length > 0){
//                 let childIndex = menuItems[i].childMenuItems.filter(x => state.url.includes(x.menuRouterLink.replace('/', '').toLowerCase()));
//                 if (childIndex) {
//                   return true;
//                 } else {
//                   count++
//                 }
//               }else{
//               count++
//             }
//             }
//           }
// if(count != 0 ){
//   this.router.navigate(['home'], { replaceUrl: true });
//   return false;

// }else{
//   return true;
// }
var url = state.url;

        }
        let checkElement = this.global.findElement(menuItems, 'childMenuItems', 'menuRouterLink', url);
if(checkElement.length > 0) {
  return true;
} 
else if(state.url == '/home') {
  this.router.navigate(['login']);
  return false;
} else {
  this.router.navigate(['home']);
  return false;
}
        }
      }
    }
}
