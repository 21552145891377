/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule} from '@ngx-translate/core';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { Pipes } from 'src/app/pipes/pipes.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NumberDirective } from './custom.directive';
import { IonicSelectableComponent, IonicSelectableHeaderTemplateDirective, IonicSelectableFooterTemplateDirective, IonicSelectableSearchFailTemplateDirective, IonicSelectableItemTemplateDirective,  } from 'ionic-selectable';



export const MY_CUSTOM_FORMATS = {
    parseInput: 'LL LT',
    fullPickerInput: 'DD-MM-YYYY HH:mm',
    datePickerInput: 'DD-MM-YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
    imports: [
      IonicSelectableComponent,
      IonicSelectableItemTemplateDirective,
      IonicSelectableSearchFailTemplateDirective,
      IonicSelectableFooterTemplateDirective,
      IonicSelectableHeaderTemplateDirective,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        OwlMomentDateTimeModule,
        Pipes,
        NgxSmartModalModule,
        TranslateModule,
        NgxSummernoteModule,

    ],
    declarations: [
        PaginationComponent,
        NumberDirective
    ],
    exports: [
      IonicSelectableComponent,
      IonicSelectableItemTemplateDirective,
      IonicSelectableSearchFailTemplateDirective,
      IonicSelectableFooterTemplateDirective,
      IonicSelectableHeaderTemplateDirective,
        PaginationComponent,
        TranslateModule,
        NgxSummernoteModule,
        NgxSmartModalModule,
        NumberDirective,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        OwlMomentDateTimeModule,
    ],
    providers: [
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        NgxSmartModalService
      ],
})

export class SharedModule {}
