import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SettingService } from './setting.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  loading: any;
  translationData = {};

  constructor(public loadingController: LoadingController, public _httpClient: HttpClient, private _router: Router, private _settingService: SettingService, private _translate: TranslateService, private global: GlobalService) {

    if ((!localStorage.translations || localStorage.translations == "undefined") && (localStorage.authenticationKey && localStorage.authenticationKey != "undefined" && localStorage.authenticationKey != "null")) {
      this.getTranslation(((localStorage.locale && !Number.isNaN(Number(localStorage.locale))) ? localStorage.locale : 1043), '', '');
    } else {
      this.geLocalTranslation();
    }

  }

  geLocalTranslation() {
    this.translationData = {};
    let localTranslation = {};
    if (!localStorage.locale || localStorage.locale == "null" || localStorage.locale == "undefined") localStorage.locale = 1033;
    if(localStorage.locale == '1033') {
      localTranslation = {
        "label-userName": "User name",
        "label-password": "Password",
        "label-domain": "Domain",
        "label-login": "Log in",
        "label-logInOAuth": "Login with OAuth",
        "label-invalidMsg": "Field required",
        "label-pleaseWait": "Please wait",
        "label-error": "Error",
        "label-errorMessage": "Error",
        "label-invalidLoginData": "Invalid user",
        "label-selectLocale": "Select locale",
        "label-forgotPassword": "Forgot password",
        "label-send": "Send",
        "label-backToLogin": "Back to login",
        "label-passwordRecoverInstruction": "Enter your Email address here to receive a new password.",
        "label-emailaddress": "Emailaddress",
        "label-passwordMismatch": "Password doesn't match",
        "label-invalidPattern": "The password must be 8 characters long and should contain letters, numbers, at least one upper case character and one special character.",
        "label-passwordRestEmailSent": "Password reset link is emailed. Please check your email and click the secure link.",
        "label-resetPassword": "Reset password",
        "label-confirmPassword": "Confirm password",
        "label-passwordChanged": "Password has been changed successful.",
        "label-passwordChangedSuccessfully": "Your password is changed successfully. Return to login page to continue.",
        "label-passwordResetLinkExpired": "Password link expired",
        "label-submit": "Submit",
        "label-invalidDomain": "Domain not found",
        "label-notPossibleForSSO": "Password can't be changed for SSO user",
        "label-userActivatedSuccessfully": "User activated successfully",
        "label-activateUser": "Activate user",
        "label-invitationLinkExpired": "This invitation link has expired.",
        "label-logInWithMicrosoft": "Login with Microsoft",
        "label-logInWithGoogle": "Login with Google",
        "label-changeDomain": "Change domain",
        "label-userNotFound": "User not found",
        "label-unauthorize": "Unauthorized: session has expired",
        "label-enable2FA": "Enable 2 factor authentication",
        "label-downloadAuthenticatorApp" : "Download the Authenticator app for your device via the link below.",
        "label-authenticatorAppSetup": "Scan the QR code below with the Authenticator app on your device and enter the 6-digit code here.",
        "label-passcode": "Passcode",
        "label-scanQRCode": "Then scan this QR code with the app.",
        "label-invalidPostCode": "Invalid postal code",
        "label-2FAVerificationEmailSent" : "Check your email for the two-factor authentication link.",
        "label-invalidOTPPattern": "Enter the 6 digit code of your Authenticator app",
        "label-setupAuthenticatorApp":"Install Authenticator application",
        "label-loginNotAllowed": "A problem occurred while logging into your account. Please contact the system administrator.",
        "label-disabledQuestion": "Quesionnaire is disabled.",
        "label-reportIncident": "Report incident",
        "label-myIncidents":"My incidents",
        "label-takeATest":"Take a test",
        "label-next": "Next",
        "label-previous": "Previous",
        "label-close": "Close",
        "label-notPassed":"Not passed",
        "label-passed":"Passed",
        "label-score":"Score",
        "label-signIn":"Sign in",
        "label-signInWith":" Sign in with",
        "label-or": "or",
        "label-orSigninWith": "or sign in with",
        "label-changeLocale" : "Languages",
        "label-cancel": "Cancel",
        "label-changeLanguage": "Change language"
      };
    } else if(localStorage.locale == '1043' ) {
      localTranslation = {
        "label-userName": "Gebruikersnaam",
        "label-password": "Wachtwoord",
        "label-domain": "Domein",
        "label-login": "Inloggen",
        "label-logInOAuth": "Inloggen with OAuth",
        "label-invalidMsg": "Ongeldige invoer. Probeer het opnieuw",
        "label-pleaseWait": "Even geduld a.u.b",
        "label-error": "Fout",
        "label-errorMessage": "Foutmelding",
        "label-invalidLoginData": "Incorrecte gebruiker",
        "label-selectLocale": "Select locale",
        "label-forgotPassword": "Wachtwoord vergeten",
        "label-send": "Verzenden",
        "label-backToLogin": "Terug naar login",
        "label-passwordRecoverInstruction": "Vul hier uw e-mailadres in om een nieuw wachtwoord te ontvangen.",
        "label-emailaddress": "Emailadres",
        "label-passwordMismatch": "Het wachtwoord komt niet overeen",
        "label-invalidPattern": "Het wachtwoord moet minstens 8 karakters lang zijn en letters, nummers, tenminste 1 hoofdletter en 1 speciaal teken bevatten.",
        "label-passwordRestEmailSent": "Er is een email verzonden met een wachtwoord reset link.",
        "label-resetPassword": "Stel wachtwoord opnieuw in",
        "label-confirmPassword": "Herhaal wachtwoord",
        "label-passwordChanged": "Uw wachtwoord is aangepast.",
        "label-passwordChangedSuccessfully": "Je wachtwoord is succesvol aangepast. Ga terug naar het inlogscherm om verder te gaan.",
        "label-passwordResetLinkExpired": "Wachtwoord link is vervallen",
        "label-submit": "Versturen",
        "label-invalidDomain": "Domein niet gevonden",
        "label-notPossibleForSSO": "Het wachtwoord kan niet worden gewijzigd voor een SSO gebruiker",
        "label-userActivatedSuccessfully": "Gebruiker geactiveerd",
        "label-activateUser": "Gebruiker activeren",
        "label-invitationLinkExpired": "Deze uitnodigingslink is verlopen.",
        "label-logInWithMicrosoft": "Inloggen met Microsoft",
        "label-logInWithGoogle": "Inloggen met Google",
        "label-changeDomain": "Wijzig domein",
        "label-userNotFound": "Ongeldige gebruiker",
        "label-unauthorize": "Niet geauthoriseerd: sessie is verlopen",
        "label-enable2FA": "Zet 2-factor authenticatie aan",
        "label-downloadAuthenticatorApp" : "Download the Authenticator app voor jouw toestel via onderstaande link.",
        "label-authenticatorAppSetup": "Scan de QR code hieronder met de Authenticator app op je toestel en vul de 6 cijferige code hier in.",
        "label-passcode": "Toegangscode",
        "label-scanQRCode": "Scan daarna deze QR code met de app.",
        "label-invalidPostCode": "Ongeldig postcode",
        "label-2FAVerificationEmailSent" : "Check je email voor de twee-factor authenticatie link.",
        "label-invalidOTPPattern": "Vul de 6-cijferige code in van je Authenticator app.",
        "label-setupAuthenticatorApp":"Installeer Authenticator app",
        "label-loginNotAllowed": "Er is een probleem opgetreden tijdens het inloggen op je account. Neem contact op met de beheerder.",
        "label-disabledQuestion":"Vragenlijst is uitgezet",
        "label-reportIncident": "Meld incident",
        "label-myIncidents":"Mijn incidenten",
        "label-takeATest":"Doe de kennistest!",
        "label-next":"Volgende",
        "label-previous": "Vorige",
        "label-close":"Sluiten",
        "label-notPassed":"Gezakt",
        "label-passed":"Geslaagd",
        "label-score":"Score",
        "label-signIn":"Log in",
        "label-signInWith":"Log in via",
        "label-or": "of",
        "label-orSigninWith": "of log in via",
        "label-changeLocale" : "Taalinstellingen",
        "label-cancel": "Annuleren",
        "label-changeLanguage": "Taal wijzigen"
      };
    }
    else if(localStorage.locale == '1031'){
      localTranslation = {
        "label-userName": "Benutzername",
        "label-password": "Passwort",
        "label-domain": "Domain",
        "label-login": "Anmeldung",
        "label-logInOAuth": "Melden Sie sich mit OAuth an",
        "label-invalidMsg": "Pflichtfeld",
        "label-pleaseWait": "Warten Sie mal",
        "label-errorMessage": "Achtung",
        "label-invalidLoginData": "Ungültiger Benutzer",
        "label-sendDiagnostic": "Diagnostik senden",
        "label-selectAnItem":"Artikel wählen",
        "label-qr/barcodeScanner":"QR / Barcode-Scanner",
        "label-beaconScanner":"Beacon Scanner",
        "label-itemFound":"Gefundene Artikel(en)",
        "label-noItemFound":"Kein Artikel gefunden",
        "label-recent":"Neueste",
        "label-applicationClose":"Anwendung beenden",
        "label-closeAndLogout":"Sind Sie sicher, dass Sie die Anwendung verlassen möchten?",
        "label-infoBlocks":"Information",
        "label-search": "Suche",
        "label-saveChanges":"Möchten Sie Änderungen sparen?",
        "label-noInternet":"Keine Internetverbindung",
        "label-writeCodeNfc":"Geben Sie den Code ein, um in NFC zu schreiben",
        "label-writeNfc":"NFC schreiben",
        "label-writeToNfc":"Zu NFC schreiben",
        "label-code":"Code",
        "label-logOut": "Ausloggen",
        "label-date": "Datum",
        "label-unauthorize": "Nicht autorisiert: Die Sitzung ist abgelaufen",
        "label-searchResults": "Ergebnis(se)",
        "label-checkLists": "Checklisten",
        "label-changeLocale" : "Sprachen",
        "label-errorOpeningFile" : "Fehler beim Öffnen der Datei",
        "label-yes": "Ja",
        "label-no": "Nein",
        "label-ok": "Ok",
        "label-cancel": "Abbrechen",
        "label-permissionDisclaimer-1": "Diese Anwendung erfordert die Erlaubnis, sowohl auf den Standort als auch auf den Speicher zuzugreifen.",
        "label-permissionDisclaimer-2": "Ohne diese Berechtigungen funktionieren die Funktionen dieser Anwendung möglicherweise nicht richtig.",
        "label-permissionDisclaimer-3" : "Um sicherzugehen, dass alles in Ordnung ist, wenden Sie bitte die richtigen Berechtigungen an und starten Sie die Anwendung neu.",
        "label-disclaimer" : "Disclaimer",
        "label-licenseInformation": "Lizenzinformationen",
        "label-version": "Ausführung",
        "label-searchText": "Suchtext",
        "label-scanText": "Text scannen",
        "label-askQuestion": "Beantworte eine Frage",
        "label-askAnotherQuestion": "Stellen Sie eine andere Frage",
        "label-messages":"Nachrichten",
        "label-noTimeToAnswer": "Ich habe keine Zeit zu antworten",
        "label-rating": "Bewertungen",
        "label-writeRemarks": "Bemerkungen",
        "label-done": "Fertig",
        "label-completed": "Abgeschlossen",
        "label-close" : "Schließen",
        "label-sessionTimeout": "Session-Timeout",
        "label-clearCache": "Cache löschen",
        "label-info": "Information",
        "label-cacheClearSuccess": "Cache cleared successfully",
        "label-rememberMe": "Erinnere dich an mich",
        "label-knowledgeQuestion": "Frage",
        "label-forgotPassword": "Passwort vergessen",
        "label-send": "Senden",
        "label-backToLogin": "Zurück zur Anmeldung",
        "label-passwordRecoverInstruction": "Geben Sie hier Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passwort zu erhalten.",
        "label-emailaddress": "E-Mail-Addresse",
        "label-passwordMismatch": "Passwort stimmt nicht überein",
        "label-invalidPattern": "Das Passwort muss 8 Zeichen lang sein und sollte Buchstaben, Zahlen, mindestens einen Großbuchstaben und ein Sonderzeichen enthalten.",
        "label-passwordRestEmailSent": "Der Link zum Zurücksetzen des Passworts wurde gesendet. Bitte überprüfen Sie Ihre E -Mails und klicken Sie auf den sicheren Link, um Ihr Passwort zurückzusetzen.",
        "label-resetPassword": "Passwort zurücksetzen",
        "label-confirmPassword": "Passwort bestätigen",
        "label-passwordChanged": "Ihr Passwort wird erfolgreich geändert. Kehren Sie zur Anmeldeseite zurück, um fortzufahren.",
        "label-passwordChangedSuccessfully": "Ihr Passwort wird erfolgreich geändert. Kehren Sie zur Anmeldeseite zurück, um fortzufahren.",
        "label-select": "Auswählen...",
        "label-selectLocale": "Sprache auswählen",
        "label-logInWithMicrosoft": "Melden Sie sich mit Microsoft an",
        "label-logInWithGoogle": "Melden Sie sich bei Google an",
        "label-changeDomain": "Domain ändern",
        "label-submit": "Einreichen",
        "label-takeTest": "Einen Test schreiben",
        "label-invalidDomain": "Domain nicht gefunden",
        "label-notPossibleForSSO" : "Für SSO -Benutzer ist es nicht möglich, ihr Passwort in PSA zu ändern.",
        "label-userActivatedSuccessfully": "Benutzer erfolgreich aktiviert",
        "label-activateUser": "Benutzer aktivieren",
        "label-invitationLinkExpired": "Dieser Einladungslink ist abgelaufen.",
        "label-userNotFound": "Benutzer existiert nicht",
        "label-enable2FA": "2-Faktor-Authentifizierung aktivieren",
        "label-downloadAuthenticatorApp" : "Laden Sie die Authenticator-App für Ihr Gerät über den unten stehenden Link herunter.",
        "label-authenticatorAppSetup": "Scannen Sie den QR-Code unten mit der Authenticator-App auf Ihrem Gerät und geben Sie den 6-stelligen Code hier ein.",
        "label-passcode": "Passcode",
        "label-scanQRCode": "Scannen Sie diesen QR-Code dann mit der App.",
        "label-invalidPostCode": "Ungültige Postleitzahl",
        "label-2FAVerificationEmailSent" : "Suchen Sie in Ihrer E-Mail nach dem Link für die Zwei-Faktor-Authentifizierung.",
        "label-invalidOTPPattern": "Geben Sie den 6 -stelligen Code Ihrer Authenticator-App ein.",
        "label-setupAuthenticatorApp":"Authenticator-Anwendung installieren",
        "label-loginNotAllowed": "Bei der Anmeldung zu Ihrem Konto ist ein Problem aufgetreten. Bitte wenden Sie sich an den Systemadministrator.",
        "label-invalidEmail": "Ungültige E-Mail-Adresse",
        "label-disabledQuestion":"Fragen sind deaktiviert",
        "label-reportIncident": "Vorfall melden",
        "label-myIncidents":"Meine Vorfälle",
        "label-takeATest":"Einen Test machen",
        "label-next":"Nächste",
        "label-previous": "Zurück",
        "label-notPassed":"Gescheitert",
        "label-passed":"Bestanden",
        "label-score":"Punktzahl",
        "label-pushNotFunctioning": "Bitte beachten Sie: Die Push-Benachrichtigungen funktionieren nicht auf Safari.",
        "label-signIn":"signIn",
        "label-signInWith":"Anmelden mit",
        "label-or": "oder",
        "label-orSigninWith": "oder anmelden mit",
        "label-changeLanguage": "Taal wijzigen"
      }
    }
    else if(localStorage.locale == '1036'){
      localTranslation = {
        "label-userName": "Nom d''utilisateur",
        "label-password": "Mot de passe",
        "label-domain": "Domaine",
        "label-login": "Connexion",
        "label-logInOAuth": "Connexion avec OAuth",
        "label-invalidMsg": "Champ obligatoire",
        "label-pleaseWait": "S''il vous plaît, attendez",
        "label-errorMessage": "Message d''erreur",
        "label-invalidLoginData": "Utilisateur invalide",
        "label-sendDiagnostic": "Envoyer des diagnostics",
        "label-selectAnItem":"Sélectionner un élément",
        "label-qr/barcodeScanner":"Scanner QR / Bar",
        "label-beaconScanner":"Scanner de balise",
        "label-itemFound":"Article(s) trouvé",
        "label-noItemFound":"Aucun article trouvé",
        "label-recent":"Récent",
        "label-applicationClose":"Quitter l''application",
        "label-closeAndLogout":"Êtes-vous sûr de vouloir quitter l''application?",
        "label-infoBlocks":"Informations",
        "label-search": "Chercher",
        "label-saveChanges":"Souhaitez-vous enregistrer des modifications?",
        "label-noInternet":"Pas de connexion Internet",
        "label-writeCodeNfc":"Entrez le code pour écrire dans NFC",
        "label-writeNfc":"Écrire NFC",
        "label-writeToNfc":"Écrivez à NFC",
        "label-code":"Code",
        "label-logOut": "Déconnecter",
        "label-date": "Date de mise à jour",
        "label-unauthorize": "Non autorisé: la session a expiré",
        "label-searchResults": "Résultat(s)",
        "label-checkLists": "Listes de contrôle",
        "label-changeLocale" : "Langues",
        "label-errorOpeningFile" : "Erreur lors de l''ouverture d''un fichier",
        "label-yes": "Oui",
        "label-no": "Non",
        "label-ok": "D''ACCORD",
        "label-cancel": "Annuler",
        "label-permissionDisclaimer-1": "Cette application nécessite une autorisation pour accéder à la fois à l''emplacement et au stockage.",
        "label-permissionDisclaimer-2": "Sans ces autorisations, les fonctionnalités de cette application pourraient ne pas fonctionner correctement.",
        "label-permissionDisclaimer-3" : "Pour vous assurer que tout est en ordre, veuillez appliquer les autorisations correctes et redémarrer l''application.",
        "label-disclaimer" : "Disclaimer",
        "label-licenseInformation": "Informations sur la licence",
        "label-version": "Version",
        "label-searchText": "Texte de recherche",
        "label-scanText": "Scanner le texte",
        "label-askQuestion": "Répondre à une question",
        "label-askAnotherQuestion": "Poser une autre question",
        "label-messages":"Messages",
        "label-noTimeToAnswer": "Je n''ai pas le temps de répondre",
        "label-rating": "Notes",
        "label-writeRemarks": "Remarques",
        "label-done": "Fait",
        "label-completed": "Complété",
        "label-close" : "Fermer",
        "label-sessionTimeout": "Expiration de la session",
        "label-clearCache": "Effacer le cache",
        "label-info": "Informations",
        "label-cacheClearSuccess": "Cache cleared successfully",
        "label-rememberMe": "Souviens-toi de moi",
        "label-knowledgeQuestion": "Question",
        "label-forgotPassword": "Mot de passe oublié",
        "label-send": "Envoyer",
        "label-backToLogin": "Retour à la page d''accueil",
        "label-passwordRecoverInstruction": "Entrez votre adresse e-mail ici pour recevoir un lien de réinitialisation de mot de passe.",
        "label-emailaddress": "Adresse e-mail",
        "label-passwordMismatch": "Le mot de passe ne correspond pas",
        "label-invalidPattern": "Le mot de passe doit comporter 8 caractères et doit contenir des lettres, des chiffres, au moins un caractère majuscule et un caractère spécial.",
        "label-passwordRestEmailSent": "Le lien de réinitialisation du mot de passe a été envoyé. Veuillez vérifier votre e-mail et cliquez sur le lien sécurisé pour réinitialiser votre mot de passe.",
        "label-resetPassword": "Réinitialiser le mot de passe",
        "label-confirmPassword": "Confirmez le mot de passe",
        "label-passwordChanged": "Uw wachtwoord is aangepast.",
        "label-passwordChangedSuccessfully": "Votre mot de passe est modifié avec succès. Retournez à la page de connexion pour continuer.",
        "label-select": "Sélectionner...",
        "label-selectLocale": "Sélectionner la langue",
        "label-logInWithMicrosoft": "Connexion avec Microsoft",
        "label-logInWithGoogle": "Connexion avec Google",
        "label-changeDomain": "Changer de domaine",
        "label-submit": "Soumettre",
        "label-takeTest": "Faire un test!",
        "label-invalidDomain": "Domaine introuvable",
        "label-notPossibleForSSO" : "Pour les utilisateurs SSO, il n''est pas possible de modifier leur mot de passe dans PSA.",
        "label-userActivatedSuccessfully": "L''utilisateur activé avec succès",
        "label-activateUser": "Activer l''utilisateur",
        "label-invitationLinkExpired": "Ce lien d''invitation a expiré.",
        "label-userNotFound": "L''utilisateur n''existe pas",
        "label-enable2FA": "Activer l''authentification à 2 facteurs",
        "label-downloadAuthenticatorApp" : "Téléchargez l''application Authenticator pour votre appareil via le lien ci-dessous.",
        "label-authenticatorAppSetup": "Scannez le code QR ci-dessous avec l''application Authenticator sur votre appareil et saisissez le code à 6 chiffres ici.",
        "label-passcode": "Code de passe",
        "label-scanQRCode": "Ensuite, scannez ce code QR avec l''application.",
        "label-invalidPostCode": "Code postal invalide",
        "label-2FAVerificationEmailSent" : "Vérifiez votre e-mail pour le lien d''authentification à deux facteurs.",
        "label-invalidOTPPattern": "Entrez le code à 6 chiffres de votre application Authenticator.",
        "label-setupAuthenticatorApp":"Installer l''application Authenticator",
        "label-loginNotAllowed": "Un problème est survenu lors de la connexion à votre compte. Veuillez contacter l''administrateur du système.",
        "label-invalidEmail": "Adresse e-mail invalide",
        "label-disabledQuestion":"Les questions sont désactivées",
        "label-reportIncident": "Signaler l''incident",
        "label-myIncidents":"Mes incidents",
        "label-takeATest":"Passer un test!",
        "label-next":"Prochain",
        "label-previous": "Précédent",
        "label-notPassed":"Manqué",
        "label-passed":"Passé",
        "label-score":"Score",
        "label-pushNotFunctioning": "Veuillez noter que les notifications push ne fonctionnent pas sur Safari.",
        "label-signIn":"Se connecter",
        "label-signInWith":"Se connecter avec",
        "label-or": "oo",
        "label-orSigninWith": "ou se connecter avec",
        "label-changeLanguage": "Taal wijzigen"
      }
    }
    if(!localStorage.translations || localStorage.translations == "undefined" || localStorage.translations == "null" || localStorage.translations == "{}") {
      this.translationData = localTranslation;
      localStorage.translations = JSON.stringify(this.translationData);
      this._translate.setTranslation(localStorage.locale, this.translationData, true);
      this._translate.use(localStorage.locale);
    } else {
      this.translationData = localTranslation;
      localStorage.translations = JSON.stringify(this.translationData);
      // let translations = JSON.parse(localStorage.translations);
      // Object.keys(translations).forEach(key => {
      //   this.translationData[key] = translations[key];
      // });
      this._translate.setTranslation(localStorage.locale, this.translationData, true);
      this._translate.use(localStorage.locale);
    }
  }

  getTranslation(lang, checklistDataId, isCategory) {
    // console.log("Get Translation Called")
    return this._settingService.getTranslations(lang, checklistDataId, isCategory)
      .then(
        (data) => {
          this.translationData = {};
          // console.log(data);
          if (data.length > 0) {
            data.forEach((translation, index) => {
              this.translationData[translation.TranslationCode] = translation.TranslationText;
            });

            localStorage.translations = JSON.stringify(this.translationData);
            if (checklistDataId || isCategory) {
              this._translate.setTranslation(lang, this.translationData, true);
              this._translate.use(lang);
            }
            else {
              this._translate.setTranslation(lang, this.translationData, false);
              this._translate.use(lang);
            }
          }
        }
      ).catch(
        (err) => {

          if (localStorage.translations && localStorage.translations != "undefined") {
            let obj = JSON.parse(localStorage.translations);
            for (let index = 0; index < obj.length; index++) {
              this.translationData[obj[index].TranslationCode] = obj[index].TranslationText;

            }
            this._translate.setTranslation(lang, this.translationData, false);
            this._translate.use(lang);
          } else {
            this._translate.setTranslation(lang, this.translationData, false);
            this._translate.use(lang);
          }

          if (err.statusText == "Unauthorized") {
            this.global.clearSettings();
            setTimeout(() => {
              this._router.navigate(['/login']);
            }, 2000);
          }

        }
      )
  }

  getLocaleTranslation(locale) {
    if(localStorage.locale && localStorage.locale != locale) {
      localStorage.removeItem('translations');
      localStorage.setItem('locale', locale);
    }
    this.geLocalTranslation();
  }

}
