import { GlobalService } from './global.service';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})

// For authentication authenticated() is not used. localStorage.authenticationKey is checked and if is found the user is authenticated otherwise not.

export class AuthService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

  constructor(private platform: Platform, private _httpClient: HttpClient, public global: GlobalService,private _mainService: MainService) {
    if(localStorage.authenticationKey != null && localStorage.userName != null && localStorage.userId != null && localStorage.authenticationKey.length > 0){
      this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('authenticationKey'));
    }else{
      this.currentUserSubject = new BehaviorSubject<any>(null);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get authenticated() : any {
    return this.currentUserSubject.value;
  }
  
  doLogin(params: any): Observable<any>{
    let httpOptions: any = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          'X-LoginSource': params.XLoginSource
      })
    };
    return this._httpClient.post(params.url ? params.url : environment.url, params.params, httpOptions).pipe(map((res:any) => {
      if (res && res.AuthenticationKey) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
        this.currentUserSubject.next(res);
      }
      return res;
    }));
  }

  doLogout(authKey): Observable<any>{
    let obj = {
      "SubscribeId" : localStorage.registeredUser? localStorage.registeredUser : ""
     }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Security-AuthKey': authKey,
    })
    return this._httpClient.put(localStorage.url+'Logout', obj ,{headers: headers}).pipe(map((res:any) => {
      return res;
    }));
  }

  // PasswordChangeRequest
  passwordChangeRequest(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost("PasswordChangeRequest", data, "");
  }

  verifyPasswordChangeRequest(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet("PasswordChangeRequest/" + id, "");
  }

  changePassword(data, id): Promise<any> {
    return this._mainService.callWebserviceHttpPost("PasswordChangeRequest/" + id + "/Reset", data, "");
  }

  verifyInvitionCode(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet("Users/activate/" + id, "");
  }

  activateUser(id, data): Promise<any> {
    return this._mainService.callWebserviceHttpPatch("Users/" + id, data,"");
  }

checkDomain(name: string): Observable<any>{
  let httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'X-LoginSource': 'weboffice',
      'X-Security-AuthKey': ""
    })
  };
  return this._httpClient.get(localStorage.ssoUrl + "/URL/" + name, httpOptions).pipe(map((res: any) => {
    return res;
  }));
}
ssoLogin(params: any): Observable<any>{
  let httpOptions: any = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': params.XLoginSource
    })
  };
  return this._httpClient.post(params.url, params.params, httpOptions).pipe(timeout(10000), map((res:any) => {
    if (res) {
      localStorage.setItem('authenticationKey', res.AuthenticationKey);
      this.currentUserSubject.next(res);
    }
    return res;
  }));
}

doVerifyPassCode(params: any): Observable<any> {
  let httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'X-LoginSource': 'weboffice',
      'X-Security-AuthKey': localStorage.otpAuthenticationKey
    })
  };
  return this._httpClient.post(localStorage.url + "Login/VerifyPassCode", params, httpOptions).pipe(timeout(10000), map((res: any) => {
    if (res) {
      localStorage.setItem('authenticationKey', res.AuthenticationKey);
      this.currentUserSubject.next(res);
    }
    return res;
  }));
}
}
