/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonSelect } from '@ionic/angular';
import { TranslationService } from './services/translation.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { environment } from 'src/environments/environment';
import { VideochatService } from './services/videochat.service';
import { SettingService } from './services/setting.service';
import { TwilioCallModalComponent } from './components/twilio-call-modal/twilio-call-modal.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  @ViewChild('domainComponent') domainComponent: IonicSelectableComponent;
  domains: any = [];
  showVideoAssistance: boolean;
  intermediateState = true;
  modal: any;
  lang: any;
  locales: any = [];
  @ViewChild(IonSelect) select: IonSelect;
  alert: any;
  timeOut: any;
  loginDomain: string;
  showReportIncident = true;
  customLocaleSelect = {
    cssClass: 'customLocaleSelect'
  };
  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Content-Type': 'application/json'
    })
  };
  menuDetails = [];
  eventValue: any = [];
  settingList: Array<any> = [];
  domainSelected= false;

  constructor(
    private platform: Platform,
    private _authService: AuthService,
    private _router: Router,
    public global: GlobalService,
    private alertController: AlertController,
    private _translate: TranslateService,
    private translationService: TranslationService,
    public ngxSmartModalService: NgxSmartModalService,
    private readonly videoChatService: VideochatService,
    public modalController: ModalController,
    private _settingService: SettingService,
    private httpClient: HttpClient
  ) {
    this.initializeApp();
    this._translate.onLangChange.subscribe((event) => {
      if (localStorage.locale) {
        this.lang = localStorage.locale;
      } else {
        this.lang = '1033';
      }
    });
    firebase.initializeApp(environment.firebaseConfig);

    if (firebase.messaging.isSupported()) {
      global.messaging = firebase.messaging();

      global.messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        if (localStorage.registeredUser != '' || !localStorage.registeredUser) {
          if (this.alert) {
            this.alert.dismiss();
          }
          if (this.modalController) {
            this.modalController.dismiss();
          }
          this.global.isCallAccepted = false;
          if (payload.data.type == 'calling') {
            if (!this.global.isCallInitiated) {
              this.global.isCallRejected = true;
              this.global.startTimer('receiving');
              if (payload.data.InfoBlocks) {
                const modalCss = 'twilio-modal-max';
                this.presentTwilioAlertConfirm(payload.data, modalCss);
              } else {
                const modalCss = 'twilio-modal-min';
                this.presentTwilioAlertConfirm(payload.data, modalCss);
              }
              clearTimeout(this.timeOut);
              this.timeOut = setTimeout(() => {
                if (!this.global.isCallAccepted) {
                  if (this.modalController) {
                    this.modalController.dismiss();
                  }
                  this.global.pauseTimer();
                }
              }, 30000);
            }
          }
          else if (payload.data.type == 'endCall') {
            this.global.showCallingUserInfo = false;
            this.global.isCallInitiated = false;
            this.global.checkBackURL = false;
            if (this.alert) {
              this.alert.dismiss();
            }
            this.global.pauseTimer();
            const msg = payload.data.fromIdentity + ' ' + this.global.translate('label-hasEndedCall');
            this.presentAlert(msg);
          }
          else if (payload.data.type == 'reject') {
            this.global.isCallInitiated = false;
            this.global.pauseTimer();
            const msg = payload.data.fromIdentity + ' ' + this.global.translate('label-hasRejectedYourCall');
            this.presentAlert(msg);
          }
          else if (payload.data.type == 'busy') {
            this.global.isCallInitiated = false;
            const msg = this.global.translate('label-inAnotherCall');
            this.global.pauseTimer();
            this.videoChatService.updateAvailableStatus(payload.data.roomName, 'true', localStorage.authenticationKey)
              .then(
                (data) => {
                  console.log('Status updated:', data);
                  this.presentAlert(msg);
                }
              )
              .catch(
                (err) => {
                  console.log(err);
                  this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'), this.global.translate('label-unauthorize'), err.status);
                }
              );
          }
          else if (payload.data.type == 'disconnectedDueToError') {
            this.global.pauseTimer();
            const msg = this.global.translate('label-callDisconnected');
            this.presentAlert(msg);
          }
          else if (payload.data.type == 'CALL_PICKED') {
            this.global.pauseTimer();
            const msg = 'call picked';
            if (this.global.isCallRejected) {
              this.presentAlert(msg);
            }
          }
          else if (payload.data.type == 'declined') {
            this.global.showCallingUserInfo = false;
            this.global.isCallInitiated = false;
            this.global.checkBackURL = false;
            this.global.pauseTimer();
            if (this.modalController) {
              this.modalController.dismiss();
            }
          }
        }
      });
     // this.getDomains();
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', async (event) => {
        if (!event.data.firebaseMessaging) {
          localStorage.notificationData = JSON.stringify(event.data);
          if (localStorage.twilioMenuEntered == 'true') {
            this.videoChatService.notificationReceived(event.data);
          } else if (localStorage.twilioMenuEntered == 'false') {
            this.global.checkBackURL = true;
            if (localStorage.authenticationKey) {
              try {
                localStorage.isSessionOutWhenCallPicked = true;
                const res = await this._settingService.ping('');
                this._router.navigate(['/video-assistant']);
              } catch (err) {
                this._router.navigate(['/login']);
              }
            } else {
              this._router.navigate(['/login']);

            }
          }

        } else {
          const payload = event.data.firebaseMessaging.payload;
          if (this._router.url != '/video-assistant') {
            if (localStorage.registeredUser != '' || !localStorage.registeredUser) {
              if (this.alert) {
                this.alert.dismiss();
              }
              if (this.modalController) {
                this.modalController.dismiss();
              }
              this.global.isCallAccepted = false;
              if (payload.data.type == 'calling') {
                if (!this.global.isCallInitiated) {
                  this.global.isCallRejected = true;
                  if (payload.data.InfoBlocks) {
                    const modalCss = 'twilio-modal-max';
                    this.presentTwilioAlertConfirm(payload.data, modalCss);
                  } else {
                    const modalCss = 'twilio-modal-min';
                    this.presentTwilioAlertConfirm(payload.data, modalCss);
                  }
                  setTimeout(() => {
                    this.global.startTimer('receiving');
                  }, 1000);
                  clearTimeout(this.timeOut);
                  this.timeOut = setTimeout(() => {
                    if (!this.global.isCallAccepted) {
                      if (this.modalController) {
                        this.modalController.dismiss();
                      }
                      this.global.pauseTimer();
                    }
                  }, 30000);
                }
              }
              else if (payload.data.type == 'endCall') {
                this.global.showCallingUserInfo = false;
                this.global.isCallInitiated = false;
                this.global.checkBackURL = false;
                if (this.alert) {
                  this.alert.dismiss();
                }
                this.global.pauseTimer();
                const msg = payload.data.fromIdentity + ' ' + this.global.translate('label-hasEndedCall');
                this.presentAlert(msg);
              }
              else if (payload.data.type == 'reject') {
                this.global.isCallInitiated = false;
                this.global.pauseTimer();
                const msg = payload.data.fromIdentity + ' ' + this.global.translate('label-hasRejectedYourCall');
                this.presentAlert(msg);
              }
              else if (payload.data.type == 'busy') {
                this.global.isCallInitiated = false;
                const msg = this.global.translate('label-inAnotherCall');
                this.global.pauseTimer();
                this.videoChatService.updateAvailableStatus(payload.data.roomName, 'true', localStorage.authenticationKey)
                  .then(
                    (data) => {
                      console.log('Status updated:', data);
                      this.presentAlert(msg);
                    }
                  )
                  .catch(
                    (err) => {
                      console.log(err);
                      this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'), this.global.translate('label-unauthorize'), err.status);
                    }
                  );
              }
              else if (payload.data.type == 'disconnectedDueToError') {
                this.global.pauseTimer();
                const msg = this.global.translate('label-callDisconnected');
                this.presentAlert(msg);
              }
              else if (payload.data.type == 'CALL_PICKED') {
                this.global.pauseTimer();
                const msg = 'call picked';
                if (this.global.isCallRejected) {
                  this.presentAlert(msg);
                }
              }
              else if (payload.data.type == 'declined') {
                this.global.showCallingUserInfo = false;
                this.global.isCallInitiated = false;
                this.global.checkBackURL = false;
                this.global.pauseTimer();
                if (this.modalController) {
                  this.modalController.dismiss();
                }
              }
            }
          } else {
            if (payload.data.type == 'calling') {
              localStorage.notificationData = JSON.stringify(payload.data);
              this.videoChatService.notificationReceived(payload.data);
            } else if (payload.data.type == 'endCall') {
              this.global.showCallingUserInfo = false;
              this.global.isCallInitiated = false;
              this.global.checkBackURL = false;
              if (this.alert) {
                this.alert.dismiss();
              }
              this.global.pauseTimer();
              const msg = payload.data.fromIdentity + ' ' + this.global.translate('label-hasEndedCall');
              this.presentAlert(msg);
            } else if (payload.data.type == 'reject') {
              this.global.isCallInitiated = false;
              this.global.pauseTimer();
              const msg = payload.data.fromIdentity + ' ' + this.global.translate('label-hasRejectedYourCall');
              this.presentAlert(msg);
            } else if (payload.data.type == 'busy') {
              this.global.isCallInitiated = false;
              const msg = this.global.translate('label-inAnotherCall');
              this.global.pauseTimer();
              this.videoChatService.updateAvailableStatus(payload.data.roomName, 'true', localStorage.authenticationKey)
                .then(
                  (data) => {
                    console.log('Status updated:', data);
                    this.presentAlert(msg);
                  }
                )
                .catch(
                  (err) => {
                    console.log(err);
                    this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'), this.global.translate('label-unauthorize'), err.status);
                  }
                );
            } else if (payload.data.type == 'disconnectedDueToError') {
              this.global.pauseTimer();
              const msg = this.global.translate('label-callDisconnected');
              this.presentAlert(msg);
            } else if (payload.data.type == 'CALL_PICKED') {
              this.global.pauseTimer();
              const msg = 'call picked';
              if (this.global.isCallRejected) {
                this.presentAlert(msg);
              }
            } else if (payload.data.type == 'declined') {
              this.global.showCallingUserInfo = false;
              this.global.isCallInitiated = false;
              this.global.checkBackURL = false;
              this.global.pauseTimer();
              if (this.modalController) {
                this.modalController.dismiss();
              }
            }

          }


        }
      });
    }



    this._router.events.subscribe(
      async (event: any) => {
        if (event instanceof NavigationStart) {
          const data: any = await this.httpClient.get('config/config.json', this.httpOptions).toPromise();
          this.global.configDomain= data.configDomainName;
          const currentApplicationVersion = data.buildRevision;
          if (localStorage.appVersion && currentApplicationVersion != localStorage.appVersion) {
            localStorage.appVersion = currentApplicationVersion;
            this.onVersionChange();
          } else {
            localStorage.appVersion = currentApplicationVersion;
          }
        }
        if (event instanceof NavigationEnd) {
          const currentURL = event.url;
          if (!currentURL.startsWith('/login') && !currentURL.startsWith('/select-locale') && !currentURL.startsWith('/login/domain') && !currentURL.startsWith('/videoplayer') &&
            !currentURL.startsWith('/oauth') && !currentURL.startsWith('/loginverification') && !currentURL.startsWith('/forgot-password') && !currentURL.startsWith('/user/invitation') && !currentURL.startsWith('/setup2fa') && !currentURL.startsWith('/privacy')) {
            if (localStorage.authenticationKey && localStorage.authenticationKey != '' && localStorage.authenticationKey != 'undefined') {
              this.ping();
            }
          }
        }
      }
    );

  }

  async ngOnInit() {
    const currentVersion = localStorage.getItem('appVersion') ?? '1.0.0';
    localStorage.setItem('appVersion', currentVersion);
    const headers = new HttpHeaders().set('Cache-Control', 'no-store, no-cache, must-revalidate');
    this.httpClient
      .get<{ buildRevision: string }>('/config/config.json', { headers })
      .subscribe(config => {
        if (config.buildRevision !== currentVersion) {
          localStorage.setItem('appVersion', config.buildRevision);
          // if (currentVersion != '1.0.0') {
          //   this.httpClient
          //     .get('', { headers, responseType: 'text' })
          //     .subscribe(() => window.location.reload());
          // }
        }
      });

    this.getDomains();
    if (localStorage.showLocaleSelect == null) {
      localStorage.showLocaleSelect = true;
    }

    if (localStorage.ImageData) {
      this.global.ImageData = localStorage.ImageData;
    } else {
      this.global.ImageData = 'assets/psa_logo.png';
      localStorage.ImageData = this.global.ImageData;
    }
    // if (localStorage.oAuth) {
    //   this.oAuth = localStorage.oAuth == "true" ? true : false;
    // }
    console.log('App Initialize');
    const data: any = await this.httpClient.get('config/config.json', this.httpOptions).toPromise();
    const currentApplicationVersion = data.buildRevision;
    if (localStorage.appVersion && currentApplicationVersion != localStorage.appVersion) {
      localStorage.appVersion = currentApplicationVersion;
      this.onVersionChange();
    }
    if (localStorage.customerColor && localStorage.customerColor != 'undefined' && localStorage.customerColor != '' && localStorage.customerColor != 'null') {
      document.documentElement.style.setProperty('--ion-color-secondary', localStorage.customerColor);
      document.documentElement.style.setProperty('--ion-color-primary', localStorage.customerColor);
      document.documentElement.style.setProperty('--ion-color-primary-shade', localStorage.customerColor);
      document.documentElement.style.setProperty('--ion-color-primary-tint', localStorage.customerColor);
    }

    if (localStorage.defaultColor && localStorage.defaultColor != 'undefined' && localStorage.defaultColor != '' && localStorage.defaultColor != 'null') {
      document.documentElement.style.setProperty('--ion-color-default', localStorage.defaultColor);
    }

    if (localStorage.locales) {
      this.global.locales = [];
      const loc = JSON.parse(localStorage.locales);
      this.global.localeLength = loc.length;
      const localesData = [];
      loc.forEach(el => {
        localesData.push({ key: el.localename, value: String(el.localeid) });
      });

      for (let i = 0; i < loc.length; i++) {
        if (localesData[i].value == '1033') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-gb', localeName: 'label-english' };
        }
        else if (localesData[i].value == '1043') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-nl', localeName: 'label-Dutch' };
        }
        else if (localesData[i].value == '1036') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-fr', localeName: 'label-French' };
        }
        else if (localesData[i].value == '1031') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-de', localeName: 'label-German' };
        }
      }
    }
    if (localStorage.locale) {
      this.lang = localStorage.locale;
    } else {
      this.lang = '1033';
      localStorage.locale = this.lang;
    }
    if (localStorage.translations && localStorage.translations != 'undefined') {
      const obj = JSON.parse(localStorage.translations);
      this._translate.setTranslation(this.lang, obj, false);
      this._translate.use(this.lang);
    }

    if (localStorage.authenticationKey && localStorage.authenticationKey != '') {
      this.translationService.getTranslation(this.lang, '', '').
        then(
          (data) => {
            this.intermediateState = false;
          }
        )
        .catch(
          (err) => {
            this.intermediateState = false;
          }
        );
      this._settingService.getIncidentCountInterval();
    } else {
      this.intermediateState = false;
    }
    if (localStorage.RoleCode && localStorage.RoleCode != '') {
      this.global.RoleCode = localStorage.RoleCode;
    }

    if (!localStorage.url || localStorage.url == 'undefined' || localStorage.url == '') {
      localStorage.url = environment.url;
      localStorage.ssoUrl = environment.ssoUrl;
    }
  }

  ionViewDidEnter() {
    this.locales = this.global.locales;
    this.global.switchDomain();
  }



  async presentAlert(msg) {
    this.alert = await this.alertController.create({
      header: this.global.translate('label-videoAssistance'),
      message: msg,
      buttons: [{
        text: this.global.translate('label-ok'),
        handler: () => {
          this.videoChatService.notificationReceived(msg);
        }
      }],
      backdropDismiss: false
    });
    await this.alert.present();
  }

  async presentTwilioAlertConfirm(payload, modalCss) {
    this.modal = await this.modalController.create({
      component: TwilioCallModalComponent,
      cssClass: modalCss,
      componentProps: {
        params: { item: payload },
      },
      backdropDismiss: false
    });

    this.modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned.data == true) {
        this.global.pauseTimer();
        localStorage.notificationData = JSON.stringify(payload);
        if (localStorage.twilioMenuEntered == 'true') {
          this.global.isCallAccepted = true;
          this.videoChatService.notificationReceived(payload);
        } else if (localStorage.twilioMenuEntered == 'false') {
          this.global.checkBackURL = true;
          this.global.isCallAccepted = true;
          this._router.navigate(['/video-assistant']);
        }
      } else if (dataReturned.data == false) {
        this.global.isCallRejected = false;
        this.global.isCallAccepted = false;
        this.global.showCallingUserInfo = false;
        this.global.pauseTimer();
        // var obj = {
        //   "Title": "Notify user",
        //   "Body": "Call rejected",
        //   "RoomName": payload.roomName,
        //   "Type": "reject",
        //   "Identity": payload.fromIdentity
        // }
        // this.videoChatService.sendNotification(obj, localStorage.authenticationKey)
        //   .then(
        //     (res) => {
        //       if (res) {
        //       }
        //     }
        //   )
        //   .catch(
        //     (err) => {
        //       console.log(err);
        //       this.global.pauseTimer();
        //       this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'), this.global.translate('label-unauthorize'), err.status);
        //     }
        //   )

      }

    });

    return await this.modal.present();

  }


  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      this.global.menuItems = [];
      localStorage.menuItems ? this.global.menuItems.push(...JSON.parse(localStorage.menuItems)) : [];

      setInterval(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('firebase-messaging-sw.js').then(registration => {
            if (registration) {
              if (registration.active) {
                registration.active.postMessage('ping');
              }
              navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                if (registration) {
                  if (registration.active) {
                    registration.active.postMessage('ping');
                  }
                }
              });
            }
          });
        }
      }, 20000);
    });
  }

  logout() {
    this.presentAlertConfirm();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.global.translate('label-applicationClose'),
      message: this.global.translate('label-closeAndLogout'),
      buttons: [
        {
          text: this.global.translate('label-no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // console.log('Confirm Cancel');
          }
        }, {
          text: this.global.translate('label-yes'),
          handler: () => {
            // console.log('Confirm Okay');
            this.global.presentLoading();
            this.global.valueChanges = false;
            this._authService.doLogout(localStorage.authenticationKey)
              .subscribe(
                () => {
                  this.global.clearSettings();
                  this.global.dismiss();
                  this.translationService.geLocalTranslation();
                  this._router.navigate(['/login']);

                },
                (err) => {
                  console.log(err);
                  this.global.clearSettings();
                  this.translationService.geLocalTranslation();
                  setTimeout(() => {
                    this.global.dismiss();
                    this._router.navigate(['/login']);
                  }, 500);
                }
              );
          }
        }],
      backdropDismiss: false
    });

    await alert.present();
  }

  changeLanguage() {
    this.global.presentLoading();
    if (localStorage.locale != this.lang) {
      localStorage.locale = this.lang;
      this.translationService.getTranslation(this.lang, '', '')
        .then(
          (data) => {
            this.global.dismiss();
          }
        ).catch(
          (err) => {
            this.global.dismiss();
          }
        );
      const data = {
        LocaleId: this.lang
      };
      const res = this._settingService.updateUserLocale(data, localStorage.userId);
    }
  }

  changeLocale() {
    this.locales = this.global.locales;
    this.ngxSmartModalService.getModal('selectLocale').backdrop = false;
    this.ngxSmartModalService.getModal('selectLocale').open();
    this.ngxSmartModalService.getModal('selectLocale').close();
    setTimeout(() => {
      this.select.open();
      this.global.getFlag();
    }, 100);
    // this.select.open();
  }
  reportIncident() {
    this._router.navigateByUrl('report-incident');
  }

  async ping() {
    try {
      const res = await this._settingService.ping('');
    } catch (err) {
      localStorage.removeItem('authenticationKey');
      this._router.navigateByUrl('/login');
      // this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'), this.global.translate('label-unauthorize'), err.status);

    }
  }

  // async oganizeKnowledgeTest() {
  //   this.global.presentLoading();
  //   this._settingService.oganizeKnowledgeTest({}).then(
  //     (res) => {
  //       this.global.dismiss();
  //       this.global.presentAlert('', this.global.translate('label-knowledgebaseNotificationSent'), '', '');
  //     },
  //     (err) => {
  //       this.global.dismiss();
  //       this.global.presentAlert(this.global.translate('label-error'), err.error.message ? this.global.translate(err.error.message): this.global.translate('label-questionNotLinked'), this.global.translate('label-unauthorize'), err.status);
  //     }
  //   ).catch(
  //     (error)=> {
  //       this.global.dismiss();
  //       this.global.presentAlert(this.global.translate('label-error'), error.error.message ? this.global.translate(error.error.message): this.global.translate('label-questionNotLinked'), this.global.translate('label-unauthorize'), error.status);
  //     }
  //   )
  // }

  checkReportIncidentStatus() {
    if (localStorage.webSetting) {
      const data = JSON.parse(localStorage.webSetting);
      data.map(element => {
        if (element.SettingName.toLowerCase() == 'enablereportincident') {
          if (element.SettingValue == 'false') {
            this.showReportIncident = false;
          }
          else {
            this.showReportIncident = true;
          }
        }
      });
    }
  }

  onVersionChange() {
    this.global.presentLoading();
    this._authService.doLogout(localStorage.authenticationKey)
      .subscribe(
        () => {
          this.global.clearSettings();
          this.global.dismiss();
          this.translationService.geLocalTranslation();
          this._router.navigate(['/login']);
          setTimeout(() => {
            window.location.reload();
          }, 10);
        },
        (err) => {
          this.global.clearSettings();
          this.global.dismiss();
          this.translationService.geLocalTranslation();
          this._router.navigate(['/login']);
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }
      ), () => {
        this.global.clearSettings();
        this.global.dismiss();
        this.translationService.geLocalTranslation();
        this._router.navigate(['/login']);
        setTimeout(() => {
          window.location.reload();
        }, 10);
      };
  }
  openChangeDomainModal() {
    this.getDomains();
    this.domainComponent.open();

    // let swtchDomain = JSON.parse(localStorage.switchDomain)
    // let domainData = [];

    // swtchDomain.forEach(x => {
    //   domainData.push({
    //     key:x.DomainName , value: String(x.DomainId)
    //   })

    // })
    // console.log(domainData)
  }
  selectChange(event: {
    component: IonicSelectableComponent;
    value: any;
  }) {
    console.log(event.value);
    this.eventValue = event.value;
    console.log(this.eventValue);
  }
  onSelect(event) {
   this.domainSelected = true;
    if (!event.isSelected) {
      this.domainComponent._selectedItems.push(event.item);
    }
  }

  cancelClicked(){
    this.domainSelected = false;

  }
  async getDomains() {
    const rolecode = localStorage.getItem('RoleCode');
    if(rolecode ==='SYSTEMADMIN' || rolecode ==='ADMIN' || rolecode ==='SUPPORT')
    {
    const domainGuid = localStorage.getItem('domainId');
    const query = 'Domains?domainId=' + domainGuid + '&linkedDomains=' + true;
    this._settingService.getSwitchDomainsName(query, localStorage.authenticationKey)
    .then(
      (webData) =>{
        this.domains = webData;
        this.global.switchDomainsName = this.domains;
      }
    );
    if (localStorage.switchDomain && localStorage.switchDomain != 'null' && JSON.parse(localStorage.switchDomain).length > 0) {
      this.domains = this.global.switchDomainsName;

    }
  }
}

  async changeDomain() {
    this.global.presentLoading();
    this.domainComponent.confirm();
    this.domainComponent.close();
    if (this.global.DomainName.toLocaleLowerCase() != this.domainComponent.value.DomainName.toLocaleLowerCase()) {
      if (this._router.url === '/home') {
        await this.global.onDomainChange.emit('domainChanged');
      }
      else {
        await this._router.navigate(['/home'], { replaceUrl: true });
      }
      if (!this.global.valueChanges) {
        // this.global.presentLoading();
        this._settingService.doDomainLogin(this.eventValue.DomainId, localStorage.authenticationKey).then(
          async (res) => {
            const response = res;
            this.global.clearchangeDomainData();
            localStorage.authenticationKey = res.AuthenticationKey;
            localStorage.setItem('domain', res.DomainName);
            localStorage.setItem('isSsoUser', response.IsSSOUser);
            localStorage.setItem('userId', response.UserId);
            localStorage.setItem('userName', response.UserName);
            localStorage.setItem('domainId', response.DomainId);
            localStorage.setItem('RoleCode', response.RoleCode);
            localStorage.setItem('locale', response.Locale);
            localStorage.setItem('locales', JSON.stringify(res.DomainLocales));
            localStorage.setItem('pwaURL', res.pwaURL);
            localStorage.setItem('switchDomain', JSON.stringify(res.Domains));
            localStorage.setItem('menuItems', JSON.stringify(res.MenuItems));

            this.global.menuItems = [];
            localStorage.menuItems ? this.global.menuItems.push(...JSON.parse(localStorage.menuItems)) : [];

            this.global.DomainName = response.DomainName;
            this.global.RoleCode = response.RoleCode;
            this.lang = localStorage.locale;
            this.loginDomain = this.global.DomainName.toLowerCase();
            this._settingService.getIncidentCountInterval();
            const settingData = await this._settingService.getSettings('', 'asc', 'settingname', localStorage.authenticationKey, true);
            this.settingList = settingData;
            localStorage.webSetting = JSON.stringify(this.settingList);
            const userflexfield  = await this._settingService.getUserFlexFields(localStorage.authenticationKey);
            localStorage.setItem('userflexfield', JSON.stringify(userflexfield));

            this.settingList.forEach((value, index) => {
              if (value.SettingName == 'OAuth') {
                localStorage.oAuth = value.SettingValue;
              }
              if (value.SettingName == 'CustomerColor') {
                localStorage.customerColor = value.SettingValue;
                localStorage.domainColor = value.SettingValue;
                this.global.domainColor = localStorage.domainColor;
              }
              if (value.SettingName == 'EnableVideoAssistance') {
                localStorage.enableVideoassistance = value.SettingValue;
                this.global.enableVideoassistance = value.SettingValue.toLowerCase();
              }
              // if (value.SettingName == "EnableUserRating") {
              //   localStorage.EnableUserRating = value.SettingValue;
              // }
            });
            let localStorageDomain = '';
            if (localStorage.domain) {
              localStorageDomain = localStorage.domain.toLowerCase();
            }
            if (this.loginDomain === localStorageDomain) {
              this.translationService.getTranslation(this.lang, '', '')
                .then(
                  (data) => {
                    localStorage.setItem('domain', this.global.DomainName);
                    if (localStorage.isSessionOutWhenCallPicked && localStorage.notificationData) {
                      this.global.isRouteFromLoginToVideoAssistance = true;
                      this._router.navigate(['/video-assistant']);
                    } else if (localStorage.externalLink && localStorage.externalLink == 'true' && localStorage.externalURL && localStorage.externalURL != '') {
                      this._router.navigateByUrl(localStorage.externalURL);
                      localStorage.removeItem('externalLink');
                      localStorage.removeItem('externalURL');
                    } else {
                      this.global.isRouteFromLoginToVideoAssistance = false;
                      this._router.navigate(['/home']);
                    }
                    this.global.dismiss();
                  }

                )

                .catch(() => {
                  localStorage.setItem('domain', this.global.DomainName);
                  this._router.navigate(['/home']);
                  this.global.dismiss();
                });
            } else {
              this._router.navigate(['/home']);
              // this._router.navigate(['/select-locale']);
              this.global.dismiss();
            }
            this.getDomainlogo();
            //  this._router.navigate(['/home']);
            // window.location.reload();
          },
          (err) => {
            console.log(err);
            this.global.dismiss();
            this.global.presentAlert(this.global.translate('label-error'), err.error.message ? this.global.translate(err.error.message) : this.global.translate('label-invalidLoginData'), '', err.status);
          }
        );
      }
    }
  }

  changeDomains(i) {
    console.log(i);
    for (const x of this.global.menuItems) {
      if (this.global.menuItems[i].menuName == 'label-changeDomain') {
        // this.global.menuItems[x].menuRouterLink=this.global.menuItems[i].menuRouterLink;
        this.openChangeDomainModal();
      }
    }
  }
  getDomainlogo() {
    this._settingService.getDomainSetting(localStorage.domain).then(
      async (data) => {
        console.log('hello', data);
        if (data.DomainLogo) {
          localStorage.removeItem('ImageData');
          localStorage.ImageData = 'data:image/png;base64,' + data.DomainLogo;
          window.location.reload();
        }
        else {
          localStorage.ImageData = 'assets/psa_logo.png';
          window.location.reload();
        }
      }
    );
  }

}
