import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-twilio-call-modal',
  templateUrl: './twilio-call-modal.component.html',
  styleUrls: ['./twilio-call-modal.component.scss'],
})
export class TwilioCallModalComponent implements OnInit {
  twilioData: any;
  infoBlock: Array<any> = [];
  constructor(private modalController: ModalController,
    public navParams: NavParams,
    private _translate: TranslateService,) {
  }

  ngOnInit() {
    this.twilioData = this.navParams.data.params;
    if(this.twilioData.item.InfoBlocks){
      this.infoBlock = JSON.parse(this.twilioData.item.InfoBlocks);
    }
    console.table(this.infoBlock);

  }

  ionViewDidLeave(){
  }

  async closeModal(status) {
      this.modalController.dismiss(status);
  }

}
